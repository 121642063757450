export const SET_AUTHTABS = 'SET_AUTHTABS';
export const API_ERROR = "API_ERROR"

export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_USER_FINISHED = 'LOGIN_USER_FINISHED';
export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const REGISTER_USER_FINISHED = 'REGISTER_USER_FINISHED';

export const REGISTER_INACTIVE_USER_FAILED = 'REGISTER_INACTIVE_USER_FAILED';
export const REGISTER_INACTIVE_USER_FINISHED = 'REGISTER_INACTIVE_USER_FINISHED';

export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export const EDIT_USER_FINISHED = 'EDIT_USER_FINISHED';
export const EDIT_USER_PASSWORD_FAILED = 'EDIT_USER_PASSWORD_FAILED';
export const EDIT_USER_PASSWORD_FINISHED = 'EDIT_USER_PASSWORD_FINISHED';

export const FORGOT_PASSWORD_CHANGE_FAILED = 'FORGOT_PASSWORD_CHANGE_FAILED';
export const FORGOT_PASSWORD_CHANGE_FINISHED = 'FORGOT_PASSWORD_CHANGE_FINISHED';
export const FORGOT_PASSWORD_SENDTOKEN_FAILED = 'FORGOT_PASSWORD_SENDTOKEN_FAILED';
export const FORGOT_PASSWORD_SENDTOKEN_FINISHED = 'FORGOT_PASSWORD_SENDTOKEN_FINISHED';


export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';


export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED';
export const OPEN_QUALIFICATION_EVENT_FAILED = 'OPEN_QUALIFICATION_EVENT_FAILED';
export const OPEN_QUALIFICATION_EVENT = 'OPEN_QUALIFICATION_EVENT';
export const OPEN_BETS_EVENT = 'OPEN_BETS_EVENT';
export const OPEN_BETS_EVENT_FAILED = 'OPEN_BETS_EVENT_FAILED';
export const EDIT_EVENT_FAILED = 'EDIT_EVENT_FAILED';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';
export const DELETE_EVENT = 'DELETE_EVENT';


export const EDIT_CLIMBS = 'EDIT_CLIMBS';
export const EDIT_CLIMBS_FAILED = 'EDIT_CLIMBS_FAILED';
export const DELETE_CLIMB = 'DELETE_CLIMB';
export const DELETE_CLIMB_FAILED = 'DELETE_CLIMB_FAILED';
export const GET_CLIMBS = 'GET_CLIMBS';
export const GET_CLIMBS_FAILED = 'GET_CLIMBS_FAILED';

export const EDIT_FINAL = 'EDIT_FINAL';
export const EDIT_FINAL_FAILED = 'EDIT_FINAL_FAILED';
export const GET_FINAL = 'GET_FINAL';
export const GET_FINAL_FAILED = 'GET_FINAL_FAILED';

export const EDIT_BETS = 'EDIT_BETS';
export const EDIT_BETS_FAILED = 'EDIT_BETS_FAILED';
export const GET_BETS = 'GET_BETS';
export const GET_BETS_FAILED = 'GET_BETS_FAILED';
export const GET_BETS_EVENT = 'GET_BETS_EVENT';
export const GET_BETS_EVENT_FAILED = 'GET_BETS_EVENT_FAILED';

export const GET_RESULTS = 'GET_RESULTS';
export const GET_RESULTS_FAILED = 'GET_RESULTS_FAILED';

export const PICK_BET_WINNER = 'PICK_BET_WINNER';
export const PICK_BET_WINNER_FAILED = 'PICK_BET_WINNER_FAILED';

export const GET_RANKING = 'GET_RANKING';
export const GET_RANKING_FAILED = 'GET_RANKING_FAILED';

export const GET_CLIMBERS = 'GET_CLIMBERS';
export const GET_CLIMBERS_FAILED = 'GET_CLIMBERS_FAILED';