import { Button, Dialog, FormControlLabel, Grid, Switch, TextField, Typography, FormGroup, DialogTitle, IconButton, Box, Checkbox, MenuItem, ListItemText, OutlinedInput, Select, FormControl, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { editEventA } from '../../redux/events'
import DeleteEvent from '../DeleteEvent';
import { genders } from '../../assets/category'

const EventEditor = ({open, handleModal, event, editEventErrors, editedEvent, editEvent, user}) => {
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [date, setDate] = useState(null)
    const [cover, setCover] = useState(null)
    const [place, setPlace] = useState(null)
  const [nb_boulders, setNbBoulders] = useState(null)
  const [nb_finals, setNbFinals] = useState(null)
  const [nb_finalists, setNbfinalists] = useState(null)
  const [season, setSeason] = useState(null)
  const [bets_opened, setBetsOpened] = useState(null)
  const [hors_serie, setHorsSerie] = useState(false)
  const [abonnes, setAbonnes] = useState(false)
  const [qualifications_opened, setQualificationsOpened] = useState(null)
  const [openDeleteEvent, setOpenDeleteEvent] = useState(false)
  const [categories , setCategories] = useState(genders.map((e) => true))
  const [selectedCategories, setSelectedCategories] = useState(genders)
  // console.log(categories)

  useEffect(() => {
    if (event?.id) {
      setTitle(event.title)
      setDate(event.date)
      setDescription(event.description)
      setPlace(event.place)
      setSeason(event.season)
      setNbBoulders(event.nb_boulders)
      setNbFinals(event.nb_finals)
      setCover(event.cover)
      setNbfinalists(event.nb_finalists)
      setBetsOpened(event.bets_opened)
      setQualificationsOpened(event.qualifications_opened)
      setHorsSerie(event.hors_serie)
      if (event.categories) {
          setCategories(JSON.parse(event.categories))
          const newSelectedCategories= []
          genders.map((elt, index) => {
            if (JSON.parse(event.categories)[index])
                newSelectedCategories.push(elt)
          })
          setSelectedCategories(newSelectedCategories)
      }
    }
  }, [event])

  const submitFormHandle = (e) => {
    if (e) {
        e.preventDefault()
    }
    editEvent(user, {
        id: event.id,
        title,
        description,
        date,
        place,
        season,
        nb_boulders,
        nb_finalists,
        nb_finals,
        cover,
        bets_opened,
        qualifications_opened,
        hors_serie,
        abonnes,
        categories: JSON.stringify(categories),
    })
  }

  useEffect(() => {
    if (editedEvent.id) {
      handleModal(false)
    }
  }, [editedEvent, handleModal])
  if (! user?.admin) {return null}

  const handleChangeCategories = (e) => {
    if (e) {
        e.preventDefault()
        if (e.target?.value) {
            const selectedCategoriesTmp = typeof value === 'string' ? e.target.value.split(',') : e.target.value
            const newCategories = categories.map((elt, index) => {
                for (const cate of selectedCategoriesTmp) {
                    if (genders.indexOf(cate) === index)
                        return true
                }
                return false
            })
            console.log(newCategories)
            setCategories(newCategories)
            setSelectedCategories(selectedCategoriesTmp)
        }
    }

  }
  return (
    <Dialog open={open} onClose={() => handleModal()} aria-labelledby="form-dialog-title" >
      <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex'}}>
        <DeleteEvent open={openDeleteEvent} handleModal={setOpenDeleteEvent} event={event} handleParentModal={handleModal} />
        <DialogTitle >
            Éditer un évènement
        </DialogTitle>
        <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
            <CloseIcon />
        </IconButton>
      <form name="login" action="" onSubmit={(e) => submitFormHandle(e)}>
        <Grid container direction='column' spacing={1} sx={{ padding: 5 }} justify='center' align='center'>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Titre de l'évènement"
                    type="text"
                    
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Saison du RockTour"
                    type="number"
                    
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                />
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Catégories</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedCategories}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput label="Catégories" />}
                        renderValue={(selected) => selected.join(', ')}
                        >
                        {genders.map((name, index) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={categories[index] ?? false} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Nombre de blocs en qualification"
                    type="number"
                    
                    value={nb_boulders}
                    onChange={(e) => setNbBoulders(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Nombre de blocs en finale"
                    type="number"
                    
                    value={nb_finals}
                    onChange={(e) => setNbFinals(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Nombre de finalistes"
                    type="number"
                    
                    value={nb_finalists}
                    onChange={(e) => setNbfinalists(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Description"
                    type="text"
                    
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Localisation de l'évènement"
                    type="text"
                    
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Lien vers une image de converture"
                    type="text"
                    
                    value={cover}
                    onChange={(e) => setCover(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Date de l'évènement"
                    type="date"
                    
                    value={date}
                    onChange={(e) => {setDate(e.target.value);console.log(date);}}
                />
            </Grid>
            <Grid item justify='flex-start'>
              <FormGroup>
                <FormControlLabel control={<Switch checked={hors_serie} />} label="Hors Série" labelPlacement="start" onChange={(event) => setHorsSerie(event.target.checked)}/>
                <FormControlLabel control={<Switch checked={qualifications_opened} />} label="Ouvrir les qualifications" labelPlacement="start" onChange={(event) => setQualificationsOpened(event.target.checked)}/>
                <FormControlLabel control={<Switch checked={bets_opened} />} label="Ouvrir les paris" labelPlacement="start" onChange={(event) => setBetsOpened(event.target.checked)}/>
                <FormControlLabel control={<Switch checked={abonnes} />} label="Classement Abonnés" labelPlacement="start" onChange={(event) => setAbonnes(event.target.checked)}/>
              </FormGroup>
            </Grid>
            <Grid item>
                <Button
                
                color='secondary'
                variant='contained'
                type="submit"
                sx={{ maxWidth: '200px' }}
                >
                    Envoyer les modifications
                </Button>
            </Grid>
            
            <Grid item>
                <Typography>
                    {editEventErrors && typeof editEventErrors.detail === 'string' ? editEventErrors.detail : ( editEventErrors && editEventErrors.detail ? editEventErrors.detail[0].msg : null)}
                </Typography>
            </Grid>
        </Grid>
        </form>
        <Button
          // variant='contained'
          type="submit"
          sx={{ maxWidth: '200px', color: 'red', marginBottom: 5 }}
          onClick={() => setOpenDeleteEvent(true)}
          >
              Supprimer l'évènement
          </Button>
        </Box>
    </Dialog>
    )
    
}

const mapStateToProps = (state) => ({
    editedEvent:  state.events.editedEvent,
    editEventErrors: state.events.editEventErrors,
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    editEvent: (user, event) => dispatch(editEventA(user, event))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventEditor)