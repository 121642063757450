import { deleteClimbAPI, editClimbsAPI, getClimbsAPI, getOtherUserClimbsAPI } from "../api_tools/climbs"
import { DELETE_CLIMB, DELETE_CLIMB_FAILED, EDIT_CLIMBS, EDIT_CLIMBS_FAILED, GET_CLIMBS, GET_CLIMBS_FAILED } from "./actionStrings"
import { genders } from '../assets/category'


export const editClimbsA = (user, climbs) => {
    return (dispatch) => {
        editClimbsAPI(user, climbs).then((res) => {
            if (res.id) {
                return dispatch({type: EDIT_CLIMBS, payload: res})
            } else {
                return dispatch({type: EDIT_CLIMBS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: EDIT_CLIMBS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: EDIT_CLIMBS_FAILED, payload: err.message });
        })
    }
}

export const deleteClimbA = (user, climb_id) => {
    return (dispatch) => {
        deleteClimbAPI(user, climb_id).then((res) => {
            if (res.status === "success") {
                return dispatch({type: DELETE_CLIMB, payload: res})
            } else {
                return dispatch({type: DELETE_CLIMB_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: DELETE_CLIMB_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: DELETE_CLIMB_FAILED, payload: err.message });
        })
    }
}

export const getClimbsA = (user, event_id) => {
    return (dispatch) => {
        getClimbsAPI(user, event_id).then((res) => {
            if (res.id) {
                return dispatch({type: GET_CLIMBS, payload: res})
            } else {
                return dispatch({type: GET_CLIMBS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_CLIMBS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_CLIMBS_FAILED, payload: err.message });
        })
    }
}

export const getOtherUserClimbsA = (user_id, event_id, user) => {
    return (dispatch) => {
        getOtherUserClimbsAPI(user_id, event_id, user).then((res) => {
            if (res.id) {
                return dispatch({type: GET_CLIMBS, payload: res})
            } else {
                return dispatch({type: GET_CLIMBS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_CLIMBS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_CLIMBS_FAILED, payload: err.message });
        })
    }
}

const defaultState = {
    editClimbsErrors: {},
    deleteClimbErrors: {},
    data: [],
    category: genders[0],
    event_id: 0,
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case EDIT_CLIMBS:
            return {...state, ...action.payload, data: JSON.parse(action.payload.data), editClimbsErrors: {}}
        case EDIT_CLIMBS_FAILED:
            return {...state, editClimbsErrors: action.payload}
        case DELETE_CLIMB:
            return state
        case DELETE_CLIMB_FAILED:
            return {...state, deleteClimbErrors: action.payload, editClimbsErrors: {}}
        case GET_CLIMBS:
            return {...state, ...action.payload, data: JSON.parse(action.payload.data), editClimbsErrors: {}}
        case GET_CLIMBS_FAILED:
            return {...state, ...defaultState, editClimbsErrors: action.payload}
        default:
            return state;
    }
}

export default reducer