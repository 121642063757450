import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { deleteEventA } from '../../redux/events'

export const DeleteEvent = ({open, handleModal, event, user, handleParentModal, deleteEvent}) => {

const submitDelete = () => {
    deleteEvent(user, event.id)
    handleParentModal(false)
    handleModal(false)
}
  return (
    <Dialog open={open} onClose={() => handleModal()} aria-labelledby="form-dialog-title" >
        <DialogTitle>
            Voulez-vous vraiment supprimer cet évènement ?
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} direction='row'>
            <Grid item>
                <Button
                    color='secondary'
                    variant='contained'
                    sx={{ maxWidth: '200px', backgroundColor: 'red' }}
                    onClick={submitDelete}
                >
                    Oui
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color='secondary'
                    variant='contained'
                    sx={{ maxWidth: '200px' }}
                    onClick={() => handleModal(false)}
                >
                    Non
                </Button>
            </Grid>
        </Grid>
        </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = (dispatch) => ({
    deleteEvent: (user, event_id) => dispatch(deleteEventA(user, event_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEvent)