import { FormGroup, Dialog, Grid, Button, DialogTitle, IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import { getResultsA } from '../../redux/results';
import { editBetsA, getBetsA } from '../../redux/bets';
import { toggleAuthTabsA } from '../../redux/authTabs';
// import { genders } from '../../assets/category'

const genders = ["Homme", "Femme"]

export const BetCard = ({open, handleModal, event, results, user, getResults, sendBets, bets, getBets, openAuthTab}) => {
    const [bet1, setBet1] = useState(bets.bet1_id)
    const [bet2, setBet2] = useState(bets.bet2_id)
    const [bet3, setBet3] = useState(bets.bet3_id)
    const [category, setCategory] = useState(genders[0])

    useEffect(() => {
        if (open && event.id && category) {
            getBets(user, event.id, category)
        }
    }, [user, open, event, category])

    useEffect(() => {
        if (open && event.id) {
            getResults(event.id)
        }
    }, [getResults, open, event])

    useEffect(() => {
        if (bets.id) {
            setBet1(bets.bet1_id)
            setBet2(bets.bet2_id)
            setBet3(bets.bet3_id)
        }
    }, [bets])

    const submitFormHandle = (e) => {
        if (e) {
            e.preventDefault()
        }
        sendBets(user, {
            ...bets,
            bet1_id: bet1,
            bet2_id: bet2,
            bet3_id: bet3,
            category,
            event_id: event.id,
        })
        handleModal(false)
    }
    return (
        <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
            <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 5}}>
                <DialogTitle >
                    Mes pronostics :
                </DialogTitle>
                <TextField
                    select
                    helperText="Choisissez une catégorie"
                    variant="outlined"
                    label="Catégorie"
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    SelectProps={{
                    native: true,
                    }}
                >
                    {genders.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                    ))}
                </TextField>
                <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                    <CloseIcon />
                </IconButton>
                <form name="bet" action="" onSubmit={(e) => submitFormHandle(e)} style={{flexDirection: 'column', alignItems: 'center', display: 'flex', paddingTop: 10}}>
                    <FormGroup>
                        <Grid container spacing={1} justify='center' align='center' direction='column'>
                            <Grid item>
                                <TextField
                                    select
                                    disabled={! event.bets_opened}
                                    helperText="La place du dieu"
                                    variant="outlined"
                                    label="1er 🥇"
                                    type="text"
                                    value={bet1}
                                    onChange={(e) => setBet1(e.target.value)}
                                    SelectProps={{
                                    native: true,
                                    }}
                                    sx={{width: '200px'}}
                                >
                                    <option value=''>
                                    </option>
                                    {results.res ? results.res[category].data?.map((row) => (
                                        row.place <= event.nb_finalists ? (
                                        <option key={row.climber.id} value={row.climber.id}>
                                            {row.climber.firstname} {row.climber.lastname} alias {row.climber.username}
                                        </option>
                                    ) : null)) : null}
                                </TextField>
                            </Grid>
                            <Grid item container direction="row" justifyContent='center' spacing={1}>

                                <Grid item>
                                    <TextField
                                        select
                                        disabled={! event.bets_opened}
                                        helperText="Tkt un jour tu gagneras...un jour"
                                        variant="outlined"
                                        label="2ème 🥈"
                                        type="text"
                                        value={bet2}
                                        onChange={(e) => setBet2(e.target.value)}
                                        SelectProps={{
                                        native: true,
                                        }}
                                        sx={{width: '200px'}}
                                    >
                                        <option value=''>
                                        </option>
                                        {results.res ? results.res[category].data?.map((row) => (
                                        row.place <= event.nb_finalists ? (
                                        <option key={row.climber.id} value={row.climber.id}>
                                            {row.climber.firstname} {row.climber.lastname} alias {row.climber.username}
                                        </option>
                                    ) : null)) : null}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        disabled={! event.bets_opened}
                                        helperText="Lui il a loupé de peu la médaille en chocolat"
                                        variant="outlined"
                                        label="3ème 🥉"
                                        type="text"
                                        value={bet3}
                                        onChange={(e) => setBet3(e.target.value)}
                                        SelectProps={{
                                        native: true,
                                        }}
                                        sx={{width: '200px'}}
                                    >
                                        <option value=''>
                                        </option>
                                        {results.res ? results.res[category].data?.map((row) => (
                                        row.place <= event.nb_finalists ? (
                                        <option key={row.climber.id} value={row.climber.id}>
                                            {row.climber.firstname} {row.climber.lastname} alias {row.climber.username}
                                        </option>
                                    ) : null)) : null}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    {event.bets_opened ? (
                    <Button type='sumit' variant='contained' color='secondary'>
                        Sauvegarder mon pari
                    </Button>
                    ) : null}
                </form>
            </Box>
        </Dialog>
    )
}


const mapStateToProps = (state) => ({
    results: state.results,
    user: state.user,
    bets: state.bets,
})

const mapDispatchToProps = (dispatch) => ({
    getResults: (event_id) => dispatch(getResultsA(event_id)),
    sendBets: (user, bets) => dispatch(editBetsA(user, bets)),
    getBets: (user, event_id, category) => dispatch(getBetsA(user, event_id, category)),
    openAuthTab: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BetCard)