import { createStore, combineReducers, applyMiddleware } from 'redux'
import logger from 'redux-logger'; // NEED TO REMOVE FOR PROD
import ReduxThunk from 'redux-thunk'
import { authTabsReducer } from './authTabs';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import userReducer from './user'
import eventsReducer from './events'
import climbsReducer from './climbs'
import betsReducer from './bets'
import resultsReducer from './results'
import rankingReducer from './ranking'
import climbersReducer from './climbers'

const debugMiddleWare = [ReduxThunk, logger];


const rootReducer = combineReducers({
  authTabs: authTabsReducer,
  user: userReducer,
  events: eventsReducer,
  climbs: climbsReducer,
  bets: betsReducer,
  results: resultsReducer,
  rankings: rankingReducer,
  climbers: climbersReducer,
})

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  blacklist: ['authTabs'],
  whitelist: ['user']
 };

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, applyMiddleware(...debugMiddleWare));
export const persistor = persistStore(store);

export default store
