import { getRankingAPI } from "../api_tools/ranking"
import { GET_RANKING, GET_RANKING_FAILED } from "./actionStrings"


export const getRankingA = (season) => {
    return (dispatch) => {
        getRankingAPI(season).then((res) => {
            return dispatch({type: GET_RANKING, payload: res});
        }, (err) => {
            if (Array.isArray(err)) {
            return dispatch({ type: GET_RANKING_FAILED, payload: err[0].msg });
        }
        return dispatch({ type: GET_RANKING_FAILED, payload: err });
        });
    }
}

const defaultState = {
    getRankingsErrors: {},
    res: null,
    season: 0,
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case GET_RANKING:
            return {...state, ...action.payload}
        case GET_RANKING_FAILED:
            return {...state, ...defaultState, getRankingsErrors: action.payload}
        default:
            return state;
    }
}

export default reducer