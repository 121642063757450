import { editBetsAPI, getBetsAPI, getBetsEventAPI } from "../api_tools/bets"
import { EDIT_BETS, EDIT_BETS_FAILED, GET_BETS, GET_BETS_EVENT, GET_BETS_EVENT_FAILED, GET_BETS_FAILED } from "./actionStrings"



export const editBetsA = (user, bets) => {
    return (dispatch) => {
        editBetsAPI(user, bets).then((res) => {
            if (res.id) {
                return dispatch({type: EDIT_BETS, payload: res})
            } else {
                return dispatch({type: EDIT_BETS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: EDIT_BETS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: EDIT_BETS_FAILED, payload: err });
        })
    }
}

export const getBetsA = (user, event_id, category) => {
    return (dispatch) => {
        getBetsAPI(user, event_id, category).then((res) => {
            if (res.id) {
                return dispatch({type: GET_BETS, payload: res})
            } else {
                return dispatch({type: GET_BETS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_BETS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_BETS_FAILED, payload: err });
        })
    }
}

export const getBetsEventA = (event_id, category) => {
    return (dispatch) => {
        getBetsEventAPI(event_id, category).then((res) => {
            if (res.length > 0) {
                return dispatch({type: GET_BETS_EVENT, payload: res})
            } else {
                return dispatch({type: GET_BETS_EVENT_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_BETS_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_BETS_EVENT_FAILED, payload: err });
        })
    }
}


const defaultState = {
    editBetsErrors: {},
    bet1_id: 0,
    bet2_id: 0,
    bet3_id: 0,
    event_id: 0,
    bets: [],
    category: null,
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case EDIT_BETS:
            return {...state, ...action.payload}
        case EDIT_BETS_FAILED:
            return {...state, editBetsErrors: action.payload}
        case GET_BETS:
            return {...state, ...action.payload}
        case GET_BETS_FAILED:
            return {...state, ...defaultState, editBetsErrors: action.payload}
        case GET_BETS_EVENT:
            return {...state, bets: action.payload}
        case GET_BETS_EVENT_FAILED:
            return {...state, ...defaultState}
        default:
            return state;
    }
}

export default reducer