
export async function editClimbsAPI(user, climbs) {
    const response = await fetch(`/api/climbs/edit`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
        body: JSON.stringify(climbs),
    } );
    const res = await response.json();
    return res;
}

export async function deleteClimbAPI(user, climb_id) {
  const response = await fetch(`/api/climbs/delete?climb_id=${climb_id}`, {
      method: 'POST', // or 'PUT'
      headers: {
        'accept': 'application/json',
        'Authorization': `${user.token_type} ${user.access_token}`
      },
  } );
  const res = await response.json();
  return res;
}

export async function getClimbsAPI(user, event_id) {
    const response = await fetch(`/api/climbs/get/me?event_id=${event_id}`, {
        headers: {
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
    } );
    const res = await response.json();
    return res;
}

export async function getOtherUserClimbsAPI(user_id, event_id, user) {
  const response = await fetch(`/api/climbs/get/other?event_id=${event_id}&user_id=${user_id}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `${user.token_type} ${user.access_token}`
      },
  } );
  const res = await response.json();
  return res;
}

export async function getResultsAPI(event_id) {
  const response = await fetch(`/api/climbs/get/results?event_id=${event_id}`, {
      headers: {
        'accept': 'application/json',
      },
  } );
  const res = await response.json();
  return res;
}