import { Grid, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

export const PrivacyPolicy = (props) => {
  return (
    <Grid
      container
      direction="column"
      padding={{ xs: 4, md: 8 }}
      justify="center"
      spacing={1}
    >
      <Grid item>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{ textDecoration: "underline" }}
        >
          POLITIQUE DE CONFIDENTIALITÉ
        </Typography>
      </Grid>
      <Grid item>
        <Typography textAlign="center" fontWeight="bold">
          rocktourapp.fr
        </Typography>
        <Typography textAlign="center">Globe Climber</Typography>
      </Grid>
      <Grid item>
        <Typography textAlign="center">
          Type de site : Application Web Progressive
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
          Le but de cette politique de confidentialité
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Le but de cette politique de confidentialité est d'informer les
          utilisateurs de notre site des données personnelles que nous
          recueillerons ainsi que les informations suivantes, le cas échéant :
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. Les données personnelles que nous recueillerons
          <br />
          b. L’utilisation des données recueillies
          <br />
          c. Qui a accès aux données recueillies
          <br />
          d. Les droits des utilisateurs du site
          <br />
          e. La politique de cookies du site
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Cette politique de confidentialité fonctionne parallèlement aux
          conditions générales d’utilisation de notre site.
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Lois applicables
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Conformément au Règlement général sur la protection des données
          (RGPD), cette politique de confidentialité est conforme aux règlements
          suivants.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Les données à caractère personnel doivent être :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. traitées de manière licite, loyale et transparente au regard de la
          personne concernée (licéité, loyauté, transparence) ;<br />
          b. collectées pour des finalités déterminées, explicites et légitimes,
          et ne pas être traitées ultérieurement d'une manière incompatible avec
          ces finalités; le traitement ultérieur à des fins archivistiques dans
          l'intérêt public, à des fins de recherche scientifique ou historique
          ou à des fins statistiques n'est pas considéré, conformément à
          l'article 89, paragraphe 1, comme incompatible avec les finalités
          initiales (limitation des finalités) ;<br />
          c. adéquates, pertinentes et limitées à ce qui est nécessaire au
          regard des finalités pour lesquelles elles sont traitées (minimisation
          des données) ;<br />
          d. conservées sous une forme permettant l'identification des personnes
          concernées pendant une durée n'excédant pas celle nécessaire au regard
          des finalités pour lesquelles elles sont traitées; les données à
          caractère personnel peuvent être conservées pour des durées plus
          longues dans la mesure où elles seront traitées exclusivement à des
          fins archivistiques dans l'intérêt public, à des fins de recherche
          scientifique ou historique ou à des fins statistiques conformément à
          l'article 89, paragraphe 1, pour autant que soient mises en œuvre les
          mesures techniques et organisationnelles appropriées requises par le
          règlement afin de garantir les droits et libertés de la personne
          concernée (limitation de la conservation) ;<br />
          e. traitées de façon à garantir une sécurité appropriée des données à
          caractère personnel, y compris la protection contre le traitement non
          autorisé ou illicite et contre la perte, la destruction ou les dégâts
          d'origine accidentelle, à l'aide de mesures techniques ou
          organisationnelles appropriées (intégrité et confidentialité).
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Le traitement n'est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. la personne concernée a consenti au traitement de ses données à
          caractère personnel pour une ou plusieurs finalités spécifiques ;
          <br />
          b. le traitement est nécessaire à l'exécution d'un contrat auquel la
          personne concernée est partie ou à l'exécution de mesures
          précontractuelles prises à la demande de celle-ci ;<br />
          c. le traitement est nécessaire au respect d'une obligation légale à
          laquelle le responsable du traitement est soumis ;<br />
          d. le traitement est nécessaire à la sauvegarde des intérêts vitaux de
          la personne concernée ou d'une autre personne physique ;<br />
          e. le traitement est nécessaire à l'exécution d'une mission d'intérêt
          public ou relevant de l'exercice de l'autorité publique dont est
          investi le responsable du traitement ;<br />
          f. le traitement est nécessaire aux fins des intérêts légitimes
          poursuivis par le responsable du traitement ou par un tiers, à moins
          que ne prévalent les intérêts ou les libertés et droits fondamentaux
          de la personne concernée qui exigent une protection des données à
          caractère personnel, notamment lorsque la personne concernée est un
          enfant.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Pour les résidents de l’État de Californie, cette politique de
          confidentialité vise à se conformer à la California Consumer Privacy
          Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la
          législation de l’État s’appliquera. Si nous constatons des
          incohérences, nous modifierons notre politique pour nous conformer à
          la loi pertinente.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Consentement
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Les utilisateurs conviennent qu’en utilisant notre site, ils
          consentent à :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          les conditions énoncées dans la présente politique de confidentialité
          et
          <br />
          la collecte, l’utilisation et la conservation des données énumérées
          dans la présente politique.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Données personnelles que nous collectons
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold">
          Données collectées automatiquement
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous ne collectons aucune donnée automatiquement sur notre site.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold">
          Données recueillies de façon non automatique
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous pouvons également collecter les données suivantes lorsque vous
          effectuez certaines fonctions sur notre site :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. Prénom et nom
          <br />
          b. Sexe
          <br />
          c. Email
          <br />
          d. Résultats des compétitions du RockTour
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Ces données peuvent être recueillies au moyen des méthodes suivantes :
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. Enregistrement d'un compte
          <br />
          b. Enregistrement des résultats de compétition
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Veuillez noter que nous ne collectons que les données qui nous aident
          à atteindre l’objectif énoncé dans cette politique de confidentialité.
          Nous ne recueillerons pas de données supplémentaires sans vous en
          informer au préalable.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Comment nous utilisons les données personnelles
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Les données personnelles recueillies sur notre site seront utilisées
          uniquement aux fins précisées dans la présente politique ou indiquées
          sur les pages pertinentes de notre site. Nous n’utiliserons pas vos
          données au-delà de ce que nous divulguerons.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Les données que nous recueillons lorsque l’utilisateur exécute
          certaines fonctions peuvent être utilisées aux fins suivantes :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. Communication
          <br />
          b. Calcul des résultats de compétition
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Avec qui nous partageons les données personnelles
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold">Employés</Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous pouvons divulguer à tout membre de notre organisation les données
          utilisateur dont il a raisonnablement besoin pour réaliser les
          objectifs énoncés dans la présente politique.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold">Tierces parties</Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous ne partageons les données utilisateur avec aucune tierces
          parties.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Dans le cas contraire nous pouvons partager uniquement les résultats
          de compétition avec des tiers à des fins de communication.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Les tiers ne seront pas en mesure d’accéder aux données des
          utilisateurs au-delà de ce qui est raisonnablement nécessaire pour
          atteindre l’objectif donné.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold">Autres divulgations</Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous nous engageons à ne pas vendre ou partager vos données avec
          d'autres tiers, sauf dans les cas suivants :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. si la loi l'exige
          <br />
          b. si elle est requise pour toute procédure judiciaire
          <br />
          c. pour prouver ou protéger nos droits légaux
          <br />
          d. à des acheteurs ou des acheteurs potentiels de cette société dans le
          cas où nous cherchons à la vendre la société
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Si vous suivez des hyperliens de notre site vers un autre site,
          veuillez noter que nous ne sommes pas responsables et n’avons pas de
          contrôle sur leurs politiques et pratiques de confidentialité.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Combien de temps nous stockons les données personnelles
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Nous ne conservons pas les données des utilisateurs au-delà de ce qui
          est nécessaire pour atteindre les fins pour lesquelles elles sont
          recueillies.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Comment nous protégeons vos données personnelles
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Afin d’assurer la protection de votre sécurité, nous utilisons le
          protocole de sécurité de la couche transport pour transmettre des
          renseignements personnels dans notre système.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          La sécurité des communications https entre les 3 parties (base de
          données, api et client) est assurée par un certificat TLS fournit par
          Let's Encrypt et le standard Oauth2 est utilisé pour la connexion avec
          l'api de l'application.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Toutes les données stockées dans notre système sont bien sécurisées et
          ne sont accessibles qu'au développeur de l'application.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Alors que nous prenons toutes les précautions raisonnables pour nous
          assurer que nos données d’utilisateur sont sécurisées et que les
          utilisateurs sont protégés, il reste toujours du risque de préjudice.
          L’Internet en sa totalité peut être, parfois, peu sûr et donc nous
          sommes incapables de garantir la sécurité des données des utilisateurs
          au-delà de ce qui est raisonnablement pratique.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Mineurs
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Le RGPD précise que les personnes de moins de 15 ans sont considérées
          comme des mineurs aux fins de la collecte de données. Les mineurs
          doivent avoir le consentement d’un représentant légal pour que leurs
          données soient recueillies, traitées et utilisées.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Vos droits en tant qu’utilisateur
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          En vertu du RGPD, les utilisateurs ont les droits suivants en tant que
          personnes concernées :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginLeft={4}>
          a. droit d’accès
          <br />
          b. droit de rectification
          <br />
          c. droit à l’effacement
          <br />
          d. droit de restreindre le traitement
          <br />
          e. droit à la portabilité des données
          <br />
          f. droit d'objection
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Vous trouverez de plus amples informations sur ces droits au chapitre
          3 (art 12-23) du RGPD.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Comment modifier, supprimer ou contester les données recueillies
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Si vous souhaitez que vos renseignements soient supprimés ou modifiés
          d’une façon ou d’une autre, veuillez communiquer avec notre agent de
          protection de la vie privée ici :<br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Loïc Dubard
          <br />
          37 RD 306, 77240 Vert-Saint-Denis
          <br />
          loic97429@gmail.com
          <br />
          07 67 34 32 89
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Modifications
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Cette politique de confidentialité peut être modifiée à l’occasion
          afin de maintenir la conformité avec la loi et de tenir compte de tout
          changement à notre processus de collecte de données. Nous recommandons
          à nos utilisateurs de vérifier notre politique de temps à autre pour
          s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous
          pouvons informer les utilisateurs par courriel des changements
          apportés à cette politique.
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontWeight="bold"
          sx={{ textDecoration: "underline" }}
          marginTop={2}
        >
          Contact
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Si vous avez des questions à nous poser, n’hésitez pas à communiquer
          avec nous en utilisant ce qui suit :<br />
          <br />
          07 67 34 32 89
          <br />
          loic97429@gmail.com
          <br />
          37 RD 306, 77240 Vert-Saint-Denis
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography marginTop={8}>Date d’entrée en vigueur : le 01 août 2022</Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
