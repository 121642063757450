import { Grid, TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router';
import { forgotPasswordChangeA } from '../../redux/user';

export const ForgotPassword = ({user, forgotPasswordChange}) => {
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    let { search } = useLocation();
    
    const query = new URLSearchParams(search);
    const token = query.get('token');
    const {email, sub, exp} = JSON.parse(atob(token.split('.')[1]))

if (token && new Date(exp * 1000) <= new Date()) {
    return (
        <Typography>
            Désolé, ce token n'est plus valide.
        </Typography>
    )
} else {

    const submitHandle = (e) => {
        if (e) {
            e.preventDefault()
        }
        if (password === passwordRepeat) {
            forgotPasswordChange(email, password, token)
        }
    }
  return (
    <form name="login" action="" onSubmit={(e) => submitHandle(e)} style={{height: '100%'}}>
    <Grid container direction='column' justify='center' align='center' spacing={3}>
        <Grid item>
            Salut, {sub} tu peux changer ton mot de passe ici :
        </Grid>
    <Grid item>
          <TextField
            required
            variant="outlined"
            label='Mot de passe'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            variant="outlined"
            error={password !== passwordRepeat}
            helperText={password !== passwordRepeat ? "Mots de passes différents" : ""}
            label='Confirmer le mot de passe'
            type="password"
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
          />
        </Grid>
        <Grid item textAlign='center'>
        <Button
          color='secondary'
          variant='contained'
          type="submit"
          sx={{ textTransform: 'none' }}
        >
          <Typography>
            Changer mon mot de passe
          </Typography>
        </Button>
        </Grid>
        <Grid item>
            <Typography color='red'>
              {user.registerUserErrors?.detail}
            </Typography>
          </Grid>
    </Grid>
    </form>
  )}
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = (dispatch) => ({
    forgotPasswordChange: (email, password, token) => dispatch(forgotPasswordChangeA(email, password, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)