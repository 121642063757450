import { createTheme } from '@mui/material/styles';
import { green, purple, grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#F49D23',
      contrastText: '#ffffff',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;