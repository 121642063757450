import React from 'react'
import { Avatar, Typography, Grid, Box } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/rocktour_cropped.png';

function LogoApp(props) {
  return (
    <Box sx={{...props.sx, textDecoration: 'unset'}} alignItems='center' component={RouterLink} to="/">
        <Avatar alt="logo" src={logo}/>
        <Typography
        variant="h6"
        noWrap
        component="div"
        color='white'
        
        >
            RockTour
        </Typography>
    </Box>
  )
}

export default LogoApp