import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import logo from '../../assets/rocktour_cropped.png';
import { connect } from 'react-redux';
import LogoApp from './logo';
import LoginButton from './LoginButton';
import { logoutUserA } from '../../redux/user';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const pages = [
  {name: '🗓️ Évènements', link: '/'},
  {name: '🏆️ Classement général', link: '/resultats'},
  {name: '🌐 Site officiel', link: 'official'},
  // {name: 'Résultats', link: 'resultats'}
];
const settings = [
  {name: '⚙️ Paramètres', link: '/profile'}, 
  {name: '🔒️ Se déconnecter'}
];

const ResponsiveAppBar = ({
    currentUser,
    logoutUser,
}) => {
  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const handleCloseUserMenu = (setting = null) => {
      setAnchorElUser(null);
      if (setting && setting === settings[1].name) {
        logoutUser()
      } else {
        settings.forEach((s) => {
          if (setting === s.name && s.link) {
            navigate(s.link)
          }
        })
      }
    };
    
    return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        {/* format mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              {/* burger button */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              keepMounted
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                  display: { xs: 'block', md: 'none' },
                }}
            >
              {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu} component={RouterLink} to={page.link}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <LogoApp sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}/>
          {/* format grand ecran */}
          <LogoApp sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}/>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                component={RouterLink}
                to={page.link}
                sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
              >
                <Typography>
                  {page.name}
                </Typography>
              </Button>
            ))}
          </Box>

            {currentUser && currentUser.loginUserFinished ? (
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                    <Button onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar src={currentUser.picture ?? null} alt={currentUser.username} sx={{ margin: 1}}/>
                        <Typography color='white'>
                          {currentUser.username}
                        </Typography>
                    </Button>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                    {settings.map((setting) => (
                        <MenuItem key={setting.name} onClick={() => handleCloseUserMenu(setting.name)}>
                          <Typography textAlign="center">{setting.name}</Typography>
                        </MenuItem>
                    ))}
                    </Menu>
                </Box>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                  <LoginButton />
              </Box>
            )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
    currentUser: state.user
})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUserA())
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveAppBar);