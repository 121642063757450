import { pickBetWinnerAPI } from "../api_tools/bets";
import { createEventAPI, getAllEventsBySeasonAPI, editEventAPI, deleteEventAPI, editFinalAPI } from "../api_tools/events";
import {
    GET_ALL_EVENTS,
    API_ERROR,
    CREATE_EVENT,
    CREATE_EVENT_FAILED,
    OPEN_BETS_EVENT_FAILED,
    OPEN_BETS_EVENT,
    OPEN_QUALIFICATION_EVENT,
    OPEN_QUALIFICATION_EVENT_FAILED,
    EDIT_EVENT,
    EDIT_EVENT_FAILED,
    DELETE_EVENT,
    DELETE_EVENT_FAILED,
    EDIT_FINAL,
    EDIT_FINAL_FAILED,
    PICK_BET_WINNER,
    PICK_BET_WINNER_FAILED,
} from "./actionStrings";



export const getAllEventsA = (season) => {
    return (dispatch) => {
        getAllEventsBySeasonAPI(season).then((events) => {
            return dispatch({type: GET_ALL_EVENTS, payload: events});
        }, (err) => {
            if (Array.isArray(err)) {
            return dispatch({ type: API_ERROR, payload: err[0].msg });
        }
        return dispatch({ type: API_ERROR, payload: err });
        });
    }
}



export const createEventA = (user, event) => {
    return (dispatch) => {
        createEventAPI(user, event).then((res) => {
            if (res.id) {
                return dispatch({type: CREATE_EVENT, payload: res})
            } else {
                return dispatch({type: CREATE_EVENT_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: CREATE_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: CREATE_EVENT_FAILED, payload: err });

        })
    }
}

export const openQualificationsA = (user, event) => {
    return (dispatch) => {
        editEventAPI(user, event).then((res) => {
            if (res.id) {
                return dispatch({type: OPEN_QUALIFICATION_EVENT, payload: res})
            } else {
                return dispatch({type: OPEN_QUALIFICATION_EVENT_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: OPEN_QUALIFICATION_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: OPEN_QUALIFICATION_EVENT_FAILED, payload: err });

        })
    }
}

export const openBetsA = (user, event) => {
    return (dispatch) => {
        editEventAPI(user, event).then((res) => {
            if (res.id) {
                return dispatch({type: OPEN_BETS_EVENT, payload: res})
            } else {
                return dispatch({type: OPEN_BETS_EVENT_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: OPEN_BETS_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: OPEN_BETS_EVENT_FAILED, payload: err });

        })
    }
}

export const editEventA = (user, event) => {
    return (dispatch) => {
        editEventAPI(user, event).then((res) => {
            if (res.id) {
                return dispatch({type: EDIT_EVENT, payload: res})
            } else {
                return dispatch({type: EDIT_EVENT_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: EDIT_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: EDIT_EVENT_FAILED, payload: err });

        })
    }
}

export const editFinalA = (user, final) => {
    return (dispatch) => {
        editFinalAPI(user, final).then((res) => {
            if (res.length > 0) {
                return dispatch({type: EDIT_FINAL, payload: res})
            } else {
                return dispatch({type: EDIT_FINAL_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: EDIT_FINAL_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: EDIT_FINAL_FAILED, payload: err });

        })
    }
}

export const deleteEventA = (user, event_id) => {
    return (dispatch) => {
        deleteEventAPI(user, event_id).then((res) => {
            if (res.status ===  "success") {
                return dispatch({type: DELETE_EVENT, payload: {id: event_id}})
            } else {
                return dispatch({type: DELETE_EVENT_FAILED, payload: res})
            }
        }, (err) => {
            console.log(err)
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: DELETE_EVENT_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: DELETE_EVENT_FAILED, payload: err });

        })
    }
}

export const pickBetWinnerA = (user, event_id) => {
    return (dispatch) => {
        pickBetWinnerAPI(user, event_id).then((res) => {
            if (res.id) {
                return dispatch({type: PICK_BET_WINNER, payload: res})
            } else {
                return dispatch({type: PICK_BET_WINNER_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: PICK_BET_WINNER_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: PICK_BET_WINNER_FAILED, payload: err });
        })
    }
}

const defaultState = {
    data: [],
    newEvent: {},
    createEventErrors: {},
    editEventErrors: {},
    editFinalErrors: {},
    pickBetWinnerErrors: {},
    editedEvent: {},
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case GET_ALL_EVENTS:
            return {...defaultState, data: action.payload};
        case CREATE_EVENT:
            return {...state, createEventErrors: {}, newEvent: action.payload}
        case OPEN_BETS_EVENT:
            return {...state, editEventErrors: {}, editedEvent: action.payload}
        case OPEN_QUALIFICATION_EVENT:
            return {...state, editEventErrors: {}, editedEvent: action.payload}
        case CREATE_EVENT_FAILED:
            return {...state, createEventErrors: action.payload, newEvent: {}}
        case OPEN_BETS_EVENT_FAILED:
            return {...state, editEventErrors: action.payload, editedEvent: {}}
        case OPEN_QUALIFICATION_EVENT_FAILED:
            return {...state, editEventErrors: action.payload, editedEvent: {}}

        case EDIT_EVENT:
            return {...state, editEventErrors: {}, editedEvent: action.payload}
        case EDIT_EVENT_FAILED:
                return {...state, editEventErrors: action.payload, editedEvent: {}}
        
        case EDIT_FINAL:
            return {...state, editFinalErrors: {'status': "success"}}
        case EDIT_FINAL_FAILED:
            return {...state, editFinalErrors: action.payload,}

        case PICK_BET_WINNER:
            return {...state, editedEvent: action.payload}
        case PICK_BET_WINNER_FAILED:
            return {...state, pickBetWinnerErrors: action.payload, editedEvent: {}}
                
        case DELETE_EVENT:
            return {...state, editEventErrors: {}, editedEvent: action.payload};
        case DELETE_EVENT_FAILED:
                return {...state, editEventErrors: action.payload, editedEvent: {}}
        default:
            return state;
    }
}

export default reducer ;