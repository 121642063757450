import { Box, Dialog, DialogTitle, IconButton, Table, TableCell, TableContainer, TableRow, Paper, TableHead, TableBody, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getBetsEventA } from '../../redux/bets';
import RepickWinner from './repickWinner';

export const ViewBets = ({
    category,
    event,
    open,
    handleModal,
    getBetsEvent,
    bets,
    results,
    user,
}) => {
    const [openPickWinner, setOpenPickWinner] = useState(false)
    useEffect(() => {
        if (open && bets.category !== category) {
            getBetsEvent(event.id, category)
        }
    }, [open])

  if (category === 'Tous') {
      return (
        <Dialog open={open} onClose={() => handleModal(false)}>
            <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 1}}>
                <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                    <CloseIcon />
                </IconButton>
                {bets.length > 0 && bets[0].winner ? (
                    <DialogTitle>
                        Le grand gagnant des paris est 
                        <Typography sx={{backgroundColor: '#F49D23', textAlign: 'center'}}>
                            {bets[0].winner.firstname} {bets[0].winner.lastname} alias {bets[0].winner.username}.
                        </Typography>
                    </DialogTitle>
                ) : (
                    <DialogTitle>
                        Le grand gagnant des paris n'a pas encore été tiré au sort.
                    </DialogTitle>
                )}
                {user.admin ? (
                    <>
                        <RepickWinner open={openPickWinner} handleModal={setOpenPickWinner} event={event} handleParentModal={handleModal} />
                        <Button 
                            variant='contained'
                            type="submit"
                            sx={{ textTransform: 'none' }}
                            onClick={() => setOpenPickWinner(true)}
                            >
                            {bets.length > 0 && bets[0].winner ? "Relancer": "Lancer"} les dés !
                        </Button>
                    </>
                ) : null}
            </Box>
        </Dialog>
      )
  }
  return (
    <Dialog fullScreen open={open} onClose={() => handleModal(false)}>
        <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 1}}>
            <DialogTitle>
                Paris (catégorie : {category})
            </DialogTitle>
            <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                <CloseIcon />
            </IconButton>
            <TableContainer component={Paper}>
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{padding: 0, background: 'white', zIndex: 3}} align="center">Parieur</TableCell>
                                <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 40}} align="center">🥇</TableCell>
                                <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 40}} align="center">🥈</TableCell>
                                <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 40}} align="center">🥉</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bets && bets.length > 0 ? bets.map((row) => {
                                if (row.bet1_id) {
                                    let backgroundColor = 'whitesmoke'
                                    if (results.res && results.res[category] && results.res[category].bets)
                                        results.res[category].bets.map((b)=> {
                                            if (b.owner.id === row.owner.id)
                                                backgroundColor = '#F49D23'
                                        })
                                    return (
                                        <TableRow key={row.id} sx={{backgroundColor}}>
                                            <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 40, fontWeight: 'bold'}} align="center">{row?.owner?.firstname} {row?.owner?.lastname}</TableCell>
                                            {Object.keys([...Array(3)]).map((x) => {
                                                let key = `bet${parseInt(parseInt(x)+1)}_id`
                                                if (results.res && results.res[category] && results.res[category].data){
                                                    return results.res[category].data.map((climber) => {
                                                        if (parseInt(climber.climber.id) === parseInt(row[key])) {
                                                            return (
                                                                <TableCell key={`cell${x}`} sx={{padding: 0, paddingLeft: 1, minWidth: 40}} align="center">{climber.climber.firstname} {climber.climber.lastname}</TableCell>
                                                            )
                                                        }   
                                                    })

                                                }
                                            })}
                                        </TableRow>
                                    )
                                }
                            }) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
        </Box>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
    results: state.results,
    bets: state.bets.bets,
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    getBetsEvent: (event_id, category) => dispatch(getBetsEventA(event_id, category)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewBets)