import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BoulderCard from '../BoulderCard'
import BetCard from '../BetCard'
import EventEditor from '../EventEditor'
import ResultCard from '../ResultCard'
import { toggleAuthTabsA } from '../../redux/authTabs'

export const EventCard = ({ event, user, errors, openAuthTab, setPauseInterval}) => {

  const [openClimbing, setOpenClimbing] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [openBets, setOpenBets] = useState(false)
  const [openEditor, setOpenEditor] = useState(false)

  useEffect(() => {
    if ((! user.loginUserFinished) && (openClimbing || openBets)) {
      openAuthTab(true)
      setOpenClimbing(false)
      setOpenBets(false)
    }
  }, [user, openClimbing, openBets, openAuthTab])

  return (
    <Card sx={{position: 'relative'}}>
      {user.loginUserFinished ? (
        <>
          <BetCard key={10 + event.id} open={openBets} handleModal={(open) => {setOpenBets(open); setPauseInterval(open)}} event={event}/>
          <BoulderCard key={event.id} open={openClimbing} handleModal={(open) => {setOpenClimbing(open); setPauseInterval(open)}} event={event}/>
        </>
      ) : null}
      <ResultCard key={event.id} open={openResults} handleModal={setOpenResults} setPauseInterval={setPauseInterval} event={event}/>
      {user.admin ? (
          <>
            <EventEditor key={event.id} open={openEditor} handleModal={(open) => {setOpenEditor(open); setPauseInterval(open)}} event={event}/>
            <Button variant='contained' onClick={() => {setOpenEditor(true); setPauseInterval(true)}} sx={{position: 'absolute', right: 0, top: 0}}>
              Éditer
            </Button>
            
          </>
        ) : null}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }}}>

        <CardMedia
          component="img"
          sx={{width: {xs: "100%", md: "200px"}, maxHeight: {xs: "140px", md: "100%"}}}
          image={event.cover}
          alt={`${event.title}cover`}
        />
      
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <CardContent>
        <Grid item container direction='column'>
          {event.qualifications_opened || event.bets_opened ? (
            <Typography color='red'>
              LIVE
            </Typography>
          ) : null}
          {event.hors_serie ? (
            <Typography color='orange'>
              HORS CIRCUIT
            </Typography>
          ) : null}
          <Typography>
            {event.title}
          </Typography>
          <Typography>
            {event.place}
          </Typography>
          <Typography>
            {event.date}
          </Typography>
          <Grid item>
            <Typography>
              {event.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {event.qualifications_opened ? (
          <Button variant='contained' color='secondary' onClick={() => {setOpenClimbing(true); setPauseInterval(true)}}>
            📝 Résultats
          </Button>
        ) : null}
        <Button variant='contained' color='secondary' onClick={() => setOpenResults(true)}>
          🏆 Classement
        </Button>
          <Button variant='contained' color='secondary' onClick={() => {setOpenBets(true); setPauseInterval(true)}}>
          🎲 Parier
          </Button>
        
      </CardActions>
      </Box>

      </Box>

            <Typography>
              {errors?.detail}
            </Typography>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.events.editEventErrors,
})

const mapDispatchToProps = (dispatch) => ({
  openAuthTab: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventCard)