import { Button, Dialog, FormControlLabel, Grid, Switch, TextField, Paper, Typography, FormGroup, DialogTitle, IconButton, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { editFinalA } from '../../redux/events'

const EditFinals = ({ category, results, open, handleModal, event, editFinalErrors, editedEvent, editFinal, user}) => {
    const [res, setRes] = useState(null)
  useEffect(() => {
    if (event?.id) {
        if (results.res && results.res[category] && results.res[category].data && ! res) {
            const toto = {}
            results.res[category].data.map((row) => {
                if (row.place <= event.nb_finalists) {
                    toto[row.climber.id] = []
                    const iterator = [...Array(parseInt(event.nb_finals ?? 4)).keys()]
                    iterator.map((x) => {
                        toto[row.climber.id].push({top: 0, zone: 0})
                    })
                }
            })
            if (results.res[category].final) {
                results.res[category].final.map((row) => {
                    if (row.climber.id && toto[row.climber.id]) {
                        toto[row.climber.id] = row.data.slice(0, event.nb_finals ?? 4)
                        if (event.nb_finals && parseInt(event.nb_finals) > parseInt(row.data.length)) {
                            [...Array(parseInt(parseInt(event.nb_finals) - parseInt(row.data.length))).keys()].map((x) => {
                                toto[row.climber.id].push({top: 0, zone: 0})
                            })
                        }
                    }
                })
            }
            setRes({...toto})
        }
    }
  }, [event, results])
  
  useEffect(() => {
    if (editFinalErrors.status === "success") {
      handleModal(false)
    }
  }, [handleModal, editFinalErrors])

  const handleResultChange = (climber, boulder, field, newRes) => {
      const toto = {...res}
      if (
            newRes >= 0 &&
            (
                (newRes > 0 && toto[climber][boulder].top > 0) ||
                parseInt(toto[climber][boulder].top) === 0 ||
                field === "top"
            )
        ) {
            
            toto[climber][boulder][field] = newRes
        }
        if (toto[climber][boulder].top > 0 && parseInt(toto[climber][boulder].zone) === 0) {
            toto[climber][boulder].zone = 1
        }
        setRes({...toto})
    }
  const submitFormHandle = (e) => {
    if (e) {
        e.preventDefault()
    }
    editFinal(user, {
        event_id: event.id,
        category,
        res
    })
  }

  if ((! user?.admin) || ! res) {return null}
  return (
    <Dialog open={open} maxWidth='lg' onClose={() => handleModal()} aria-labelledby="form-dialog-title" >
      <Box sx={{position: 'relative', width: '100%', flexDirection: 'column', alignItems: 'center', display: 'flex'}}>
        <DialogTitle >
            Éditer les finales
        </DialogTitle>
        <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
            <CloseIcon />
        </IconButton>
        <TableContainer component={Paper}>
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{position: "sticky", left: 0, background: 'white', zIndex: 3, borderRight: '1px solid', padding: 1,}}>Grimpeur</TableCell>
                                {[...Array(event.nb_finals ?? 4).keys()].map((x) => {
                                    return (
                                        <TableCell colSpan={2} key={`bloc ${x}`} align="center" sx={{borderLeft: '1px solid', padding: 0,}}>Bloc {x+1}</TableCell>
                                    )
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{position: "sticky", left: 0, background: 'white', zIndex: 3, borderRight: '1px solid', padding: 0,}}> </TableCell>
                                {[...Array((event.nb_finals ?? 4) * 2).keys()].map((x) => {
                                    if (x%2 === 0) {
                                        return (
                                            <TableCell key={`head ${x}`} align="center" sx={{borderLeft: '1px solid', padding: 0,}}>Top</TableCell>
                                        )
                                    } else {
                                        return (
                                            <TableCell key={`head ${x}`} align="center" sx={{padding: 0}}>Zone</TableCell>
                                        )
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {results?.res ? results.res[category].data?.map((row) => {
                            if (row.climber.id && res[row.climber.id]) {
                                return (

                                
                            <TableRow
                                key={row.climber.username}
                            >
                                <TableCell key={`cell${row.climber.username}`} align="right" sx={{padding: 0, paddingRight: 1, position: "sticky", left: 0, background: 'white', zIndex: 3, borderRight: '1px solid'}}>{row.climber.firstname} {row.climber.lastname}</TableCell>
                                {[...Array((event.nb_finals ?? 4) * 2).keys()].map((x) => {
                                    if (x%2 === 0) {
                                        return (
                                            <TableCell key={`${row.climber.id} ${x}`} align="center" sx={{borderLeft: '1px solid', padding: 0,}}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    value={res[row.climber.id][parseInt(x/2)].top}
                                                    onChange={(e) => handleResultChange(row.climber.id, parseInt(x/2), "top", e.target.value)}
                                                    sx={{width: '60px'}}
                                                />
                                            </TableCell>
                                        )
                                    } else {
                                        return (
                                            <TableCell key={`${row.climber} ${x}`} align="center" sx={{padding: 0,}}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    value={res[row.climber.id][parseInt(x/2)].zone}
                                                    onChange={(e) => handleResultChange(row.climber.id, parseInt(x/2), "zone", e.target.value)}
                                                    sx={{width: '60px'}}
                                                />
                                            </TableCell>
                                        )
                                    }
                                })}
                            </TableRow>
                            )
                        }
                    }
                        ) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
      <form name="login" action="" onSubmit={(e) => submitFormHandle(e)}>
        <Grid container direction='column' spacing={1} sx={{ padding: 5 }} justify='center' align='center'>
            <Grid item>
            
            </Grid>
            <Grid item>
                <Button
                
                color='secondary'
                variant='contained'
                type="submit"
                sx={{ maxWidth: '200px' }}
                >
                    Envoyer les modifications
                </Button>
            </Grid>
            
            <Grid item>
                <Typography>
                    {editFinalErrors && typeof editFinalErrors.detail === 'string' ? editFinalErrors.detail : ( editFinalErrors && editFinalErrors.detail ? editFinalErrors.detail[0].msg : null)}
                </Typography>
            </Grid>
        </Grid>
        </form>
        </Box>
    </Dialog>
    )
    
}

const mapStateToProps = (state) => ({
    editedEvent:  state.events.editedEvent,
    editFinalErrors: state.events.editFinalErrors,
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    editFinal: (user, event) => dispatch(editFinalA(user, event))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditFinals)