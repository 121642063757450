import { Toolbar, Typography, AppBar, Link } from '@mui/material'
import React from 'react'

function BottomBar() {
  return (
    <AppBar position="relative" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
            <Link href="/privacy" color="inherit">Politique de confidentialité</Link>
        </Toolbar>
      </AppBar>
  )
}

export default BottomBar