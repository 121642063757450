import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toggleAuthTabsA } from '../../redux/authTabs'
import { loginUserA, loginUserGoogleA } from '../../redux/user'

const loadScript = (src) =>
new Promise((resolve, reject) => {
  if (document.querySelector(`script[src="${src}"]`)) return resolve()
  const script = document.createElement('script')
  script.src = src
  script.onload = () => resolve()
  script.onerror = (err) => reject(err)
  document.body.appendChild(script)
})

const LoginCard = ({user, loginUser, redirect, handleModal, changeIndex, loginUserGoogle, authTabOpened}) => {
  const [loginValue, setLoginValue] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {
    /* global google */
    const src = 'https://accounts.google.com/gsi/client'
    loadScript(src).then(() => {
      google.accounts.id.initialize({
        client_id: "247673667481-f6mr2q4g4j16i2qu1t8a6g53n57eg4e1.apps.googleusercontent.com",
        callback: loginUserGoogle
      })

      google.accounts.id.renderButton(
        document.getElementById("signInGoogle"),
        {theme: "outline", size: "large"}
      )
    }).catch(console.error)
    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  const loginUserHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    loginUser(loginValue, password, redirect)
  }

  useEffect(() => {
    if (user.loginUserFinished && authTabOpened) {
      handleModal(false)
    }
  }, [user, handleModal, authTabOpened])
  return (
    <form name="login" action="" onSubmit={(e) => loginUserHandle(e)} style={{height: '100%'}}>
    <Grid sx={{height: '100%'}} spacing={2} flexDirection='column' container justifyContent='center' textAlign='center' alignItems='center'>
        <Grid item>
          <TextField
            required
            variant="outlined"
            label="Nom d'utilisateur ou email"
            type="username"
            
            value={loginValue}
            onChange={(e) => setLoginValue(e.target.value)}
          />
        </Grid>
          <Grid item>
          <TextField
            required
            
            variant="outlined"
            label='Mot de passe'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          </Grid>
          <Grid item>
            <Button
              
              color='secondary'
              variant='contained'
              type="submit"
              sx={{ textTransform: 'none', maxWidth: '200px'  }}
            >
                Se Connecter
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => changeIndex(2)}>
              J'ai oublié mon mot de passe
            </Button>
          </Grid>
          <Grid item>
            <div id="signInGoogle"></div>
          </Grid>
          <Grid item>
            <Typography color='red'>
              {user.loginUserErrors?.detail}
            </Typography>
          </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  redirect: state.authTabs.redirect,
  authTabOpened: state.authTabs.open,
})

const mapDispatchToProps = (dispatch) => ({
    loginUser: (loginValue, password) => dispatch(loginUserA(loginValue, password)),
    handleModal: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
    loginUserGoogle: (response) => dispatch(loginUserGoogleA(response)),
  })
export default connect(mapStateToProps, mapDispatchToProps)(LoginCard)