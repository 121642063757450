import { getAllUsersAPI } from "../api_tools/users/users"
import { GET_CLIMBERS, GET_CLIMBERS_FAILED } from "./actionStrings"
import { genders } from '../assets/category'

export const getClimbersA = (user, event_id) => {
    return (dispatch) => {
        getAllUsersAPI(user).then((res) => {
            if (res && res.length > 0) {
                return dispatch({type: GET_CLIMBERS, payload: res})
            } else {
                return dispatch({type: GET_CLIMBERS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_CLIMBERS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_CLIMBERS_FAILED, payload: err });
        })
    }
}

const defaultState = {
    data: [],
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {

        case GET_CLIMBERS:
            return {...state, ...action.payload, data: action.payload}
        case GET_CLIMBERS_FAILED:
            return {...state, ...defaultState, editClimbsErrors: action.payload}
        default:
            return state;
    }
}

export default reducer