import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Routes,
} from "react-router-dom";
import ResponsiveAppBar from './components/AppBar';
import BottomBar from './components/BottomBar';
import LoginDialog from './components/LoginDialog';

import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Official from './pages/Official';
import Privacy from './pages/Privacy';
import Profile from './pages/Profile';
import Resultats from './pages/Resultats';
import { toggleAuthTabsA } from './redux/authTabs';
import { logoutUserA } from './redux/user';
import theme from './styles/themes';

function App({createEventErrors, editEventErrors, openAuthTab, logoutUser, token}) {
  useEffect(() => {
    // console.log(new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000))
    if (
      createEventErrors?.detail === "Impossible de valider les crédentials"  ||
      editEventErrors?.detail === "Impossible de valider les crédentials"  ||
      editEventErrors?.detail === "Not authenticated"  ||
      createEventErrors?.detail === "Not authenticated" 
    ) {
      logoutUser()
      openAuthTab(true)
    }
    if (token && new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000) <= new Date()) {
      logoutUser()
      openAuthTab(false)
    }
  }, [editEventErrors, createEventErrors, openAuthTab, logoutUser, token])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{minHeight: 'calc(100vh - 56px)'}}>
        <ResponsiveAppBar />
        <LoginDialog />
        <Routes>
          <Route index element={<Home />}/>
          <Route path='/profile' element={<Profile />}/>
          <Route path='/forgotPassword' element={<ForgotPassword />}/>
          <Route path='/official' element={<Official />}/>
          <Route path='/resultats' element={<Resultats />}/>
          <Route path='/privacy' element={<Privacy />}/>
        </Routes>
      </Box>
        <BottomBar />
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  createEventErrors: state.events.createEventErrors,
  editEventErrors: state.events.editEventErrors,
  token: state.user.access_token,
})

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(logoutUserA()),
    openAuthTab: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
