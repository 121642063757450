import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { pickBetWinnerA } from '../../redux/events'

export const RepickWinner = ({open, handleModal, event, user, handleParentModal, pickBetWinner}) => {

const submitPickBetWinner = () => {
    pickBetWinner(user, event.id)
    handleParentModal(false)
    handleModal(false)
}
  return (
    <Dialog open={open} onClose={() => handleModal()} aria-labelledby="form-dialog-title" >
        <DialogTitle>
            Voulez-vous vraiment lancer les dés ?
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} direction='row'>
            <Grid item>
                <Button
                    color='secondary'
                    variant='contained'
                    sx={{ maxWidth: '200px', backgroundColor: 'red' }}
                    onClick={submitPickBetWinner}
                >
                    Oui
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color='secondary'
                    variant='contained'
                    sx={{ maxWidth: '200px' }}
                    onClick={() => handleModal(false)}
                >
                    Non
                </Button>
            </Grid>
        </Grid>
        </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = (dispatch) => ({
    pickBetWinner: (user, event_id) => dispatch(pickBetWinnerA(user, event_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RepickWinner)