import { Checkbox, FormGroup, Dialog, Grid, FormControlLabel, Button, DialogTitle, IconButton, TextField, Autocomplete, Switch } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import { editClimbsA, getOtherUserClimbsA } from '../../redux/climbs';
import { toggleAuthTabsA } from '../../redux/authTabs';
import { genders } from '../../assets/category'
import { editUserA } from '../../redux/user'
import { getClimbersA } from '../../redux/climbers';
import CreateClimber from './CreateClimber';


export const AddClimber = ({open, climbers, getClimbers, handleModal, event, editClimbs, user, climbs, openAuthTab, editUser, getClimbs}) => {
    const [boulders, setBoulders] = useState([...Array(event.nb_boulders).keys()].map((x) => {return false}))
    const [newClimber, setNewClimber] = useState(null)
    const [category, setCategory] = useState(genders[0])
    const [abonnes, setAbonnes] = useState(false)
    const [openCreateClimber, setOpenCreateClimber] = useState(false)

    useEffect(() => {
        if (newClimber && newClimber.id && open && event.id)
            getClimbs(newClimber.id, event.id, user)
        if (! newClimber) {
            setBoulders([...Array(event.nb_boulders).keys()].map((x) => {return false}))
            setAbonnes(false)
        }
    }, [getClimbs, newClimber, user, open, event])

    useEffect(() => {
        if (climbs?.data && climbs.data.length > 0 && newClimber) {
            setBoulders(climbs.data)
            setAbonnes(climbs.abonnes)
        } else {
            setBoulders([...Array(event.nb_boulders).keys()].map((x) => {return false}))
            setAbonnes(false)
        }
    }, [climbs, newClimber, event.nb_boulders])

    useEffect(() => {
        if (open && (! climbers | climbers.length === 0)) {
            getClimbers(user)
        }
    }, [getClimbers, user, open, climbers])

    useEffect(() => {
        console.log(newClimber)
        if (newClimber && newClimber.gender) {
            setCategory(newClimber.gender)
        }
    }, [newClimber])

    const handleChange = (index) => {
        return (e) => {
            const newBoulders = [...boulders];
            newBoulders[index] = e.target.checked;
            console.log(newBoulders);
            setBoulders(newBoulders);
        }
    }

    const submitFormHandle = (e) => {
        if (e) {
            e.preventDefault()
        }
        editClimbs(user, {
            data: JSON.stringify(boulders),
            event_id: event.id,
            category: category,
            abonnes,
            climber_id: newClimber.id
        })
        handleModal(false)

    }


    return (
        <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
            <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', paddingBottom: 4, paddingLeft: 4, paddingRight: 4}}>
                <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle >
                    Interface Admin pour ajouter/modifier un résultat en qualif
                </DialogTitle>
                <Grid container direction="row">
                    <Grid item>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={climbers}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Chercher un grimpeur" />}
                            onChange={(e, v) => setNewClimber(v)}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={() => setOpenCreateClimber(true)}>
                            Créer nouveau
                        </Button>
                        <CreateClimber open={openCreateClimber} handleModal={setOpenCreateClimber} />
                    </Grid>
                </Grid>
                <form name="boulder" action="" onSubmit={(e) => submitFormHandle(e)} style={{flexDirection: 'column', alignItems: 'center', display: 'flex'}}>
                    <FormGroup>
                    <TextField
                        select
                        helperText="Choisissez une catégorie"
                        variant="outlined"
                        label="Catégorie"
                        type="text"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {genders.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                        ))}
                    </TextField>
                    {event.abonnes ? (
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={abonnes} />} label={`Abonné(e) ${event.place}`} labelPlacement="start" onChange={(e) => setAbonnes(e.target.checked)}/>
                        </FormGroup>
                    ) : null}
                    <Grid container spacing={1} justify='center' align='center'>
                        {boulders.map((element, index) => {
                            return (
                                <Grid key={index} item xs={6}>
                                    <FormControlLabel
                                        key={`bloc_${index}`}
                                        control={<Checkbox checked={element} onChange={handleChange(index)}/>}
                                        label={`Bloc ${1 + index}`}
                                        labelPlacement="start"
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    </FormGroup>
                    <Button type='sumit' variant='contained' color='secondary'>
                        Sauvegarder !
                    </Button>
                </form>
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    climbs: state.climbs,
    user: state.user,
    climbers: state.climbers.data,
})

const mapDispatchToProps = (dispatch) => ({
    getClimbs: (user_id, event_id, user) => dispatch(getOtherUserClimbsA(user_id, event_id, user)),
    editClimbs: (user, climbs) => dispatch(editClimbsA(user, climbs)),
    openAuthTab: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
    editUser: (new_user, token) => dispatch(editUserA(new_user, token)),
    getClimbers: (user) => dispatch(getClimbersA(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddClimber)
