import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom';

export const Official = (props) => {
    window.open("https://www.rocktour.globeclimber.com/")
  return (
    <Navigate to="/" />
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Official)