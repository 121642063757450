export async function getBetsAPI(user, event_id, category) {
    const response = await fetch(`/api/bets/get/me?event_id=${event_id}&category=${category}`, {
        headers: {
            'accept': 'application/json',
            'Authorization': `${user.token_type} ${user.access_token}`
          },
    });
    const res = await response.json();
    return res;
}

export async function getBetsEventAPI(event_id, category) {
    const response = await fetch(`/api/bets/get?event_id=${event_id}&category=${category}`, {
        headers: {
            'accept': 'application/json',
            // 'Authorization': `${user.token_type} ${user.access_token}`
          },
    });
    const res = await response.json();
    return res;
}

export async function editBetsAPI(user, bets) {
    const response = await fetch(`/api/bets/edit`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
        body: JSON.stringify(bets),
    } );
    const res = await response.json();
    return res;
}

export async function pickBetWinnerAPI(user, event_id) {
    const response = await fetch(`/api/events/pickBetWinner?event_id=${event_id}`, {
        method: 'POST', // or 'PUT'
        headers: {
            'accept': 'application/json',
            'Authorization': `${user.token_type} ${user.access_token}`
        },
    });
    const res = await response.json();
    return res;
}