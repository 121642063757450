export async function getAllEventsAPI() {
    const response = await fetch(`/api/events/get_all`);
    const res = await response.json();
    return res;
}

export async function getAllEventsBySeasonAPI(season) {
    const response = await fetch(`/api/events/get_all_by_season?season=${season}`);
    const res = await response.json();
    return res;
}

export async function createEventAPI(user, event) {
    const response = await fetch(`/api/events/create`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
        body: JSON.stringify(event),
    } );
    const res = await response.json();
    return res;
}

export async function editEventAPI(user, event) {
    const response = await fetch(`/api/events/edit`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
        body: JSON.stringify(event),
    } );
    const res = await response.json();
    return res;
}

export async function editFinalAPI(user, final) {
    const response = await fetch(`/api/finals/edit?event_id=${final.event_id}&category=${final.category}`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
        body: JSON.stringify(final.res),
    } );
    const res = await response.json();
    return res;
}

export async function getFinalsAPI(event_id, category) {
    const response = await fetch(`/api/finals/get?event_id=${event_id}&category=${category}`);
    const res = await response.json();
    return res;
}

export async function deleteEventAPI(user, event_id) {
    const response = await fetch(`/api/events/delete?event_id=${event_id}`, {
        method: 'POST', // or 'PUT'
        headers: {
          'accept': 'application/json',
          'Authorization': `${user.token_type} ${user.access_token}`
        },
    } );
    const res = await response.json();
    return res;
}