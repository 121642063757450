import React from 'react'
import { connect } from 'react-redux'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { getCurrentSeason } from '../../utils/getCurrentSeason';

export const SeasonPicker = ({season, setSeason}) => {
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
        <Grid item>
            <IconButton disabled={Number(season - 1) < 2022} onClick={() => {setSeason(Number(season - 1))}}>
                <NavigateBeforeIcon />
            </IconButton>
        </Grid>
        <Grid item>
            <Typography>
                Saison {Number(season - 1)} - {Number(season)}
            </Typography>
        </Grid> 
        <Grid item>
            <IconButton disabled={Number(getCurrentSeason()) < Number(season + 1)} onClick={() => {setSeason(Number(season + 1))}}>
                <NavigateNextIcon />
            </IconButton>
        </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonPicker)