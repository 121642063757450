import { getResultsAPI } from "../api_tools/climbs"
import { GET_RESULTS, GET_RESULTS_FAILED } from "./actionStrings"


export const getResultsA = (user, event_id) => {
    return (dispatch) => {
        getResultsAPI(user, event_id).then((res) => {
            if (res.event_id) {
                return dispatch({type: GET_RESULTS, payload: res})
            } else {
                return dispatch({type: GET_RESULTS_FAILED, payload: res})
            }
        }
        ).catch((err) => {
            if (err.detail && Array.isArray(err.detail)) {
                return dispatch({ type: GET_RESULTS_FAILED, payload: err.detail[0].msg });
            }
            return dispatch({ type: GET_RESULTS_FAILED, payload: err });
        })
    }
}

const defaultState = {
    editResultsErrors: {},
    res: null,
    event_id: 0,
}

const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case GET_RESULTS:
            return {...state, ...action.payload}
        case GET_RESULTS_FAILED:
            return {...state, ...defaultState, editResultsErrors: action.payload}
        default:
            return state;
    }
}

export default reducer