import { Button, Grid, TextField, Typography, Link } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { registerUserA } from '../../redux/user'
import { mainGenders as genders } from '../../assets/category'

const RegisterCard = ({registerUser, user}) => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [gender, setGender] = useState(genders[0])
  const registerUserHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (password === passwordRepeat) {
      registerUser({
        username,
        email,
        firstname,
        lastname,
        password,
        gender,
      })
    }
  }
  return (
    <form name="register" action="" onSubmit={(e) => registerUserHandle(e)} sx={{height: '100%'}}>
      <Grid sx={{ paddingTop: '10px'}} container spacing={2} direction='column' justify='center' align='center'>
        <Grid item container direction='row' spacing={1} justifyContent='center'>
          <Grid item>
            <TextField
              required
              variant="outlined"
              label="Prénom"
              type="firstname"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              variant="outlined"
              label="Nom"
              type="lastname"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            required
            variant="outlined"
            label="Pseudo"
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            variant="outlined"
            label="Adresse email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            select
            helperText="Choisissez votre catégorie principale"
            variant="outlined"
            label="Catégorie principale"
            type="text"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            SelectProps={{
              native: true,
            }}
          >
            {genders.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            required
            variant="outlined"
            label='Mot de passe'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            variant="outlined"
            error={password !== passwordRepeat}
            helperText={password !== passwordRepeat ? "Mots de passes différents" : ""}
            label='Confirmer le mot de passe'
            type="password"
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
          />
        </Grid>
        <Grid item textAlign='center'>
        <Button
          color='secondary'
          variant='contained'
          type="submit"
          sx={{ textTransform: 'none' }}
        >
          <Typography>
            Créer mon compte
          </Typography>
        </Button>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            En créant un compte vous acceptez la <Link href="/privacy">politique de confidentialité</Link> du site.
          </Typography>
        </Grid>
        <Grid item>
            <Typography color='red'>
              {user.registerUserErrors?.detail}
            </Typography>
          </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => ({
  registerUser: (user) => dispatch(registerUserA(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCard)