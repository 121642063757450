import { Button, Checkbox, Dialog, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createEventA } from '../../redux/events'
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { getCurrentSeason } from '../../utils/getCurrentSeason';
import { genders } from '../../assets/category'


export const EventCreator = ({event, createEventErrors, createEvent, open, handleModal, user }) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState(new Date(Date.now()).toISOString().substr(0, 10))
    const [place, setPlace] = useState('')
    const [cover, setCover] = useState('https://www.rocktour.globeclimber.com/wp-content/uploads/2017/09/globe-climber-ROCKTOUR.png')
    const [nb_boulders, setNbBoulders] = useState(30)
    const [hors_serie, setHorsSerie] = useState(false)
    const [abonnes, setAbonnes] = useState(false)
    const [nb_finals, setNbFinals] = useState(4)
    const [season, setSeason] = useState(getCurrentSeason())
    const [categories , setCategories] = useState(genders.map((e) => true))
    const [selectedCategories, setSelectedCategories] = useState(genders)

    const createEventHandle = (e) => {
        if (e) {
            e.preventDefault()
        }
        createEvent(user, {
            title,
            description,
            date,
            place,
            season,
            nb_boulders,
            nb_finals,
            cover,
            hors_serie,
            bets_opened: false,
            abonnes,
            categories: JSON.stringify(categories),
        })
      }
    
      useEffect(() => {
        if (event.id) {
          handleModal(false)
        }
      }, [event, handleModal])

      const handleChangeCategories = (e) => {
        if (e) {
            e.preventDefault()
            if (e.target?.value) {
                const selectedCategoriesTmp = typeof value === 'string' ? e.target.value.split(',') : e.target.value
                const newCategories = categories.map((elt, index) => {
                    for (const cate of selectedCategoriesTmp) {
                        if (genders.indexOf(cate) === index)
                            return true
                    }
                    return false
                })
                console.log(newCategories)
                setCategories(newCategories)
                setSelectedCategories(selectedCategoriesTmp)
            }
        }
    }
    return (
    <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
      <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex'}}>
        <DialogTitle >
            Créer un évènement
        </DialogTitle>
        <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
            <CloseIcon />
        </IconButton>
        <form name="login" action="" onSubmit={(e) => createEventHandle(e)}>
        <Grid container direction='column' spacing={1} sx={{ padding: 5 }} justify='center' align='center'>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Titre de l'évènement"
                    type="text"
                    
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Saison du RockTour"
                    type="number"
                    
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                />
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Catégories</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedCategories}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput label="Catégories" />}
                        renderValue={(selected) => selected.join(', ')}
                        >
                        {genders.map((name, index) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={categories[index] ?? false} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Nombre de blocs en qualification"
                    type="number"
                    
                    value={nb_boulders}
                    onChange={(e) => setNbBoulders(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Nombre de blocs en finale"
                    type="number"
                    
                    value={nb_finals}
                    onChange={(e) => setNbFinals(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Description"
                    type="text"
                    
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Localisation de l'évènement"
                    type="text"
                    
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Lien vers une image de converture"
                    type="text"
                    
                    value={cover}
                    onChange={(e) => setCover(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    variant="outlined"
                    label="Date de l'évènement"
                    type="date"
                    
                    value={date}
                    onChange={(e) => {setDate(e.target.value);console.log(date);}}
                />
            </Grid>
            <Grid item justify='flex-start'>
              <FormGroup>
                <FormControlLabel control={<Switch checked={hors_serie} />} label="Hors Série" labelPlacement="start" onChange={(event) => setHorsSerie(event.target.checked)}/>
                <FormControlLabel control={<Switch checked={abonnes} />} label="Classement Abonnés" labelPlacement="start" onChange={(event) => setAbonnes(event.target.checked)}/>
              </FormGroup>
            </Grid>
            <Grid item>
                <Button
                
                color='secondary'
                variant='contained'
                type="submit"
                sx={{ maxWidth: '200px' }}
                >
                    Créer l'évènement
                </Button>
            </Grid>
            <Grid item>
                <Typography>
                    {createEventErrors && typeof createEventErrors.detail === 'string' ? createEventErrors.detail : ( createEventErrors && createEventErrors.detail ? createEventErrors.detail[0].msg : null)}
                </Typography>
            </Grid>
        </Grid>
        </form>
        </Box>
    </Dialog>
    )
}

const mapStateToProps = (state) => ({
    event:  state.events.newEvent,
    createEventErrors: state.events.createEventErrors,
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    createEvent: (user, event) => dispatch(createEventA(user, event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventCreator)