import {
    Card,
    Checkbox,
    FormGroup,
    Dialog,
    Grid,
    FormControlLabel,
    Button,
    DialogTitle,
    IconButton,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableBody,
    TextField,
    Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { connect } from 'react-redux'
import { getResultsA } from '../../redux/results';
import EditFinals from '../EditFinals';
import { Plane } from 'react-loader-spinner';
import DeleteClimb from '../DeleteClimb';
import { genders as categories } from '../../assets/category'
import { CSVLink } from "react-csv";
import ViewBets from '../ViewBets';
import AddClimber from '../AddClimber';


export const ResultCard = ({open, handleModal, event, getResults, user, results, setPauseInterval}) => {
    const [genders, setGenders] = useState(["Tous", ...categories])
    const [category, setCategory] = useState(genders[0])
    const [editFinalOpen, setEditFinalOpen] = useState(false)
    const [viewBetsOpen, setViewBetsOpen] = useState(false)
    const [AddClimberOpen, setAddClimberOpen] = useState(false)
    const [openDeleteClimb, setOpenDeleteClimb] = useState(false) 
    const [climbToDelete, setClimbToDelete] = useState(null)
    const csvHeaderQualifications = [
        { label: "Place", key: "place" },
        { label: "Prenom", key: "firstname" },
        { label: "Nom", key: "lastname" },
        { label: "Pseudo", key: "username" },
        { label: "Points", key: "points" },
    ];
    Object.keys([...Array(event.nb_boulders)]).map((x) => {
        csvHeaderQualifications.push({ label: `Bloc ${parseInt(parseInt(x) + 1)}`, key: `boulder${parseInt(parseInt(x) + 1)}` })
    })

    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (event && event.categories) {
            const newGenders = ["Tous"]
            categories.map((elt, index) => {
                if (JSON.parse(event.categories)[index]) {
                    newGenders.push(elt)
                    if (event.abonnes) {
                        newGenders.push(`${elt} Abonné(e)s`)
                    }
                }
             })
            setGenders(newGenders)
        }
    }, [event.categories])

    useEffect(() => {
        const toto = []
        if (results && results.res && results.res[category].values) {
            const entry = {
                place: "",
                firstname: "",
                lastname: "",
                username: "",
                points: "",
            }
            results.res[category].values.map((res, index) => {
                entry[`boulder${parseInt(parseInt(index) + 1)}`] = res
            })
            toto.push(entry)
        }
        if (results && results.res && results.res[category].data) {
            results.res[category].data.map((row) => {
                const entry = {
                    place: row.place,
                    firstname: row.climber.firstname,
                    lastname: row.climber.firstname,
                    username: row.climber.username,
                    points: row.points,
                }
                row.boulders.map((res, index) => {
                    entry[`boulder${parseInt(parseInt(index) + 1)}`] = String(res) === 'true' ? 'true' : ''
                })
                toto.push(entry)
            })
            setCsvData(toto)
        }
    }, [results, category])
    
    useEffect(() => {
        if (!editFinalOpen && open) {
            getResults(event.id)
        }
    }, [editFinalOpen, open, event, getResults])
    
    const handleDeleteClimb = (climb_id) => {
        setClimbToDelete(climb_id)
        setOpenDeleteClimb(true)
    }
    return (
        <Dialog fullScreen open={open} onClose={() => handleModal(false)}>
            <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 1}}>
                <DialogTitle >
                    Résultats
                </DialogTitle>
                <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                    <CloseIcon />
                </IconButton>
                {user.admin ? (
                    <DeleteClimb climb_id={climbToDelete} open={openDeleteClimb} handleModal={setOpenDeleteClimb}/>
                ) : null}
               {results.res ? (
                   <>
                <TextField
                    select
                    helperText="Choisissez une catégorie"
                    variant="outlined"
                    label="Catégorie"
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {genders.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                    ))}
                </TextField>
                {category === "Tous" ? null : (
                    <>
                <Typography>
                    Finales
                </Typography>
                <EditFinals category={category} open={editFinalOpen} handleModal={(open) => {setEditFinalOpen(open); setPauseInterval(open)}} event={event} results={results}/>
                <TableContainer component={Paper}>
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} sx={{padding: 0, background: 'white', zIndex: 3}}>{user.admin? (
                                    <Button 
                                        color='secondary'
                                        variant='contained'
                                        type="submit"
                                        sx={{ textTransform: 'none' }}
                                        onClick={() => {setEditFinalOpen(true); setPauseInterval(true)}}
                                    >
                                        Éditer
                                    </Button>
                                    ) : null}
                                </TableCell>
                                {[...Array(event.nb_finals ?? 4).keys()].map((x) => {
                                    return (
                                        <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 40}} key={`bloc ${x}`} align="right">Bloc {x+1}</TableCell>
                                    )
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding: 0, paddingLeft: 1, background: 'white', zIndex: 3}}>Place</TableCell>
                                <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1, background: 'white', zIndex: 3}} align="center">Grimpeur</TableCell>
                                <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1, background: 'white', zIndex: 3}} align="center">Total</TableCell>
                                {[...Array(event.nb_finals ?? 4).keys()].map((x) => {
                                    return (
                                        <TableCell sx={{padding: 0}}></TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {results?.res ? results.res[category].final.map((row) => (
                            <TableRow
                                key={row.climber}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'whitesmoke', fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }}
                            >
                                <TableCell component="th" scope="row" sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }}>
                                    {row.place === 1 ? '🥇' : (
                                        row.place === 2 ? '🥈' : (
                                            row.place === 3 ? '🥉' : row.place
                                    ))}
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }}>{row.climber.firstname} {row.climber.lastname}</TableCell>
                                <TableCell align='center' sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }}>{row.tops}T{row.zones}Z {row.tops_tries} {row.zones_tries}</TableCell>
                                {row.data.map((data, index) => {
                                        if (data) {
                                            return (
                                                <TableCell sx={{fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal", padding: 0, paddingLeft: 1}} key={`${row.climber} ${index}`} align="right">t{data.top}z{data.zone}</TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell key={`${row.climber} ${index}`} align="right" sx={{fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal", padding: 0, paddingLeft: 1}}></TableCell>
                                            )
                                        }
                                })}
                            </TableRow>
                        )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>

                
                {/* {results.res[category] && results.res[category].bets && results.res[category].bets.length > 0 ? (
                    results.res[category].bets.length === 1 ? (
                        <Typography>
                            Le gagnant du pari pour cette catégorie est {results.res[category].bets[0].owner.firstname} {results.res[category].bets[0].owner.lastname} alias {results.res[category].bets[0].owner.lastname}
                        </Typography>

                    ) : (
                        <Typography>
                            Les gagnants du pari pour cette catégorie sont <br/>
                            {results.res[category].bets.map((row) => {
                                return (
                                    <>
                                        - {row.owner.firstname} {row.owner.lastname} alias {row.owner.username}<br/>
                                    </>
                                )
                            })}
                        </Typography>
                        
                    )
                ) : null} */}
                </>
                )}
                {(user.admin || ! event.bets_opened) && (category === "Homme" || category === "Femme" || category === "Tous") ? (
                    <>
                    <ViewBets open={viewBetsOpen} handleModal={setViewBetsOpen} category={category} event={event}/>
                    <Button 
                        color='secondary'
                        variant='contained'
                        type="submit"
                        sx={{ textTransform: 'none', margin: 2}}
                        onClick={() => setViewBetsOpen(true)}
                    >
                        {category === "Tous" ? "Grand Gagnant des Paris" : "Voir les paris"}
                    </Button>
                    </>
                ) : null}
                <Typography sx={{ paddingTop: 2}}>
                    Qualifications
                </Typography>
                {user.admin ? (
                    <>
                        <CSVLink data={csvData} headers={csvHeaderQualifications} filename={`rocktour_qualifications_${event.date}_${category}.csv`}>
                            Exporter en format CSV
                        </CSVLink>
                        <AddClimber open={AddClimberOpen} handleModal={(open) => {setAddClimberOpen(open); setPauseInterval(open)}} event={event}/>
                        <Button 
                        color='secondary'
                        variant='contained'
                        type="submit"
                        sx={{ textTransform: 'none', margin: 2}}
                        onClick={() => {setAddClimberOpen(true); setPauseInterval(true)}}
                    >
                        Ajouter/modifier les résultats d'un(e) grimpeur(se)
                    </Button>
                </>
                ) : null}
                <TableContainer sx={{paddingTop: 2}} component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right"></TableCell>
                                {[...Array(event.nb_boulders).keys()].map((x) => {
                                    return (
                                        <TableCell sx={{padding: 0, paddingLeft: 1, minWidth: 50}} key={`bloc ${x}`} align="right">Bloc {x+1}</TableCell>
                                    )
                                })}
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1}}>Place</TableCell>
                                <TableCell sx={{padding: 0, paddingRight: 1}}>Grimpeur</TableCell>
                                <TableCell align="right" sx={{padding: 0, paddingRight: 1}}>Points</TableCell>
                                {[...Array(event.nb_boulders).keys()].map((x) => {
                                    return (
                                        <TableCell sx={{padding: 0, paddingLeft: 1}} key={`value ${x}`} align="right">{results?.res ? results.res[category].values[x] : 1000}pts</TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {results?.res ? results.res[category].data?.map((row) => (
                            <TableRow
                                key={row.climber}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: row.place <= event.nb_finalists ? '#F49D23' : 'whitesmoke' }}
                            >
                                <TableCell component="th" scope="row" sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }}>
                                    {row.place}
                                </TableCell>
                                <TableCell sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }} align="right">
                                    {row.climber.firstname} {row.climber.lastname}
                                </TableCell>
                                <TableCell sx={{ fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal" }} align="right">
                                    {row.points}
                                </TableCell>
                                {row.boulders.map((boulderDone, index) => {
                                        if (boulderDone) {
                                            return (
                                                <TableCell sx={{padding: 0, paddingRight: 1, fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}} key={`${row.climber} ${index}`} align="right"><CheckCircleOutlineIcon /></TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell sx={{padding: 0, paddingRight: 1, fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}} key={`${row.climber} ${index}`} align="right"></TableCell>
                                            )
                                        }
                                })}
                                {user.admin ? (
                                    <TableCell>
                                        <Button 
                                            variant='contained'
                                            type="submit"
                                            sx={{ textTransform: 'none' }}
                                            onClick={() => handleDeleteClimb(row.id)}
                                        >
                                            Supprimer
                                        </Button>
                                    </TableCell>
                                ) : null}
                            </TableRow>
                        )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
                ) : (
                    <Grid container direction='row' sx={{width: '100%' }} textAlign='center' justify="center" align='center'>
                        <Grid item sx={{width: '100%' }}>
                            <Plane sx={{ justifyContent: 'center' }} ariaLabel="loading-indicator" />
                        </Grid>
                    </Grid>
                   )}
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    results: state.results,

    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    getResults: (event_id) => dispatch(getResultsA(event_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultCard)
