import { AccountCircle } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { toggleAuthTabsA } from '../../redux/authTabs'

export const LoginButton = (props) => {
  return (
    <IconButton
      id="loginButton"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => props.handleModal(true)}
      color="inherit"
    >
      <AccountCircle />
    </IconButton>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.loginUserFinished | false
})

const mapDispatchToProps = (dispatch) => ({
  handleModal: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton)