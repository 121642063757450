import { Dialog, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
// import TabPanel from '@mui/lab/TabPanel';
import { toggleAuthTabsA } from '../../redux/authTabs'
import ForgotPasswordCard from './ForgotPasswordCard'
import LoginCard from './LoginCard'
import RegisterCard from './RegisterCard'

const LoginDialog = (props) => {
    // console.log(auth)
    const [currentTab, setCurrentTab] = useState(props.authTabs.tab | 0)

    return (
        <Dialog open={props.authTabs.open} onClose={() => props.handleModal(false)} aria-labelledby="form-dialog-title">
            <Tabs
                value={currentTab}
                onChange={(e, index) => setCurrentTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Se connecter" style={{ textTransform: 'none', whiteSpace: 'nowrap' }} />
                <Tab label="Créer un compte" style={{ textTransform: 'none', whiteSpace: 'nowrap' }}/>
                {/* <Tab label="Mot de passe oublié" style={{ textTransform: 'none', whiteSpace: 'nowrap' }}/> */}
            </Tabs>
            <SwipeableViews index={currentTab} onChangeIndex={setCurrentTab}>
                <LoginCard changeIndex={setCurrentTab}/>
                <RegisterCard />
                <ForgotPasswordCard />
            </SwipeableViews>
        </Dialog>
  )
}

const mapStateToProps = (state) => ({
    authTabs: state.authTabs,
})

const mapDispatchToProps = (dispatch) => ({
    handleModal: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
})

LoginDialog.url = '/login';

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog)