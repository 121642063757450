import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import EditEventModal from '../../components/EventEditor';
import EventCard from '../../components/EventCard';
import EventCreator from '../../components/EventCreator';
import { getAllEventsA } from '../../redux/events';
import { Plane } from 'react-loader-spinner';
import moment from 'moment';
import SeasonPicker from '../../components/SeasonPicker';
import { getCurrentSeason } from '../../utils/getCurrentSeason';


export const Home = ({user, getAllEvents, events, newEvent, editedEvent}) => {
    const [creatorOpen, setCreatorOpen] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [ season, setSeason ] = useState(getCurrentSeason());
    const [pauseInterval, setPauseInterval] = useState(false)
    useEffect(() => {
        if (newEvent?.id || editedEvent?.id)
            getAllEvents(season)
    }, [newEvent, editedEvent, getAllEvents])

    useEffect(() => {
        getAllEvents(season)
    }, [getAllEvents, season])

    useEffect(() => {
        let intervalId = setInterval(() => {pauseInterval ? console.log("interval paused"): getAllEvents(season)}, 20000)
        return () => clearInterval(intervalId)
    }, [getAllEvents, season, pauseInterval])


    return (
        <>
            <Grid container direction='column' spacing={1} padding={5} justify='center'>
                <Grid item>
                    {user.username ? (
                        <Typography>
                            Salut {user.username}, ça perfe aujourd'hui ?
                        </Typography>
                    ) : (
                        <Typography>
                            Connecte toi pour pouvoir rentrer tes blocs ou faire des paris !
                        </Typography>
                    )}
                </Grid>
                {user.admin ? (
                    <>
                    <EventCreator open={creatorOpen} handleModal={(open) => {setCreatorOpen(open) ; setPauseInterval(open)}} />
                    <Button sx={{ marginBottom: 4 }} variant='contained' color='secondary' onClick={()=> {setCreatorOpen(true); setPauseInterval(true)}}>
                        Créer un évènement
                    </Button>
                    </>
                ) : null}
                <Grid item>

                <SeasonPicker season={season} setSeason={setSeason} />
                </Grid>
                {events && events.length !== 0 ? (
                    <Grid item container direction='column' spacing={1}>
                        {events.map((event) => (
                            <Grid key={event.title} item>
                                <EventCard setPauseInterval={setPauseInterval} key={event.title} event={event} handleModal={setEditorOpen}/>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid item container sx={{width: '100%', justifyContent: 'center' }} direction="column">
                        <Grid item>
                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>Pas d'évènement pour cette saison pour le moment.</Typography>
                        </Grid>
                        <Grid item justify='center'>
                            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                                <Plane ariaLabel="loading-indicator" />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    events: state.events.data,
    user: state.user,
    editedEvent:  state.events.editedEvent,
    newEvent:  state.events.newEvent,
})

const mapDispatchToProps = (dispatch) => ({
    getAllEvents: (season) => dispatch(getAllEventsA(season))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)