import {
    loginUserAPI,
    registerUserAPI,
    forgotPasswordChangeAPI,
    forgotPasswordSendTokenAPI,
    editUserAPI,
    editUserPasswordAPI,
    registerInactiveUserAPI,
    loginUserGoogleAPI
} from '../api_tools/users/users';
import {
    EDIT_USER_FAILED,
    EDIT_USER_FINISHED,
    EDIT_USER_PASSWORD_FAILED,
    EDIT_USER_PASSWORD_FINISHED,
    FORGOT_PASSWORD_CHANGE_FAILED,
    FORGOT_PASSWORD_CHANGE_FINISHED,
    FORGOT_PASSWORD_SENDTOKEN_FAILED,
    FORGOT_PASSWORD_SENDTOKEN_FINISHED,
    LOGIN_USER_FAILED,
    LOGIN_USER_FINISHED,
    LOGOUT_USER,
    REGISTER_INACTIVE_USER_FAILED,
    REGISTER_INACTIVE_USER_FINISHED,
    REGISTER_USER_FAILED,
    REGISTER_USER_FINISHED
} from './actionStrings';
// import { redirectTo } from './redirect'



export const loginUserA = (username, password) => {
    return (dispatch) => {
        loginUserAPI(username, password).then((user) => {
            if (user.access_token) {
                return dispatch({type: LOGIN_USER_FINISHED, payload: user})
            } else {
                return dispatch({type: LOGIN_USER_FAILED, payload: user});
            }
        }, (err) => {
            console.log(err);
            return dispatch({type: LOGIN_USER_FAILED, payload: err});
        })
    }
}

export const loginUserGoogleA = (response) => {
    return (dispatch) => {
        if (response.credential) {
            loginUserGoogleAPI(response.credential).then((user) => {
                if (user.access_token) {
                    return dispatch({type: LOGIN_USER_FINISHED, payload: user})
                } else {
                    return dispatch({type: LOGIN_USER_FAILED, payload: user});
                }
            }, (err) => {
                console.log(err);
                return dispatch({type: LOGIN_USER_FAILED, payload: err});
            })
        } else {
            return dispatch({type: LOGIN_USER_FAILED, payload: response});
        }
    }
}



export const forgotPasswordChangeA = (email, password, token) => {
    return (dispatch) => {
        forgotPasswordChangeAPI(email, password, token).then((res) => {
            if (res.id) {
                dispatch(loginUserA(email, password));
                return dispatch({type: FORGOT_PASSWORD_CHANGE_FINISHED, payload: {}});
            } else {
                return dispatch({type: FORGOT_PASSWORD_CHANGE_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: FORGOT_PASSWORD_CHANGE_FAILED, payload: err});
        });
    }
}

export const editUserA = (new_user, token) => {
    return (dispatch) => {
        editUserAPI(new_user, token).then((res) => {
            if (res.id) {
                return dispatch({type: EDIT_USER_FINISHED, payload: res});
            } else {
                return dispatch({type: EDIT_USER_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: EDIT_USER_FAILED, payload: err});
        });
    }
}

export const editUserPasswordA = (currentPassword, password, token) => {
    return (dispatch) => {
        editUserPasswordAPI(currentPassword, password, token).then((res) => {
            if (res.id) {
                return dispatch({type: EDIT_USER_PASSWORD_FINISHED, payload: {}});
            } else {
                return dispatch({type: EDIT_USER_PASSWORD_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: EDIT_USER_PASSWORD_FAILED, payload: err});
        });
    }
}

export const forgotPasswordSendTokenA = (email) => {
    return (dispatch) => {
        forgotPasswordSendTokenAPI(email).then((res) => {
            if (res.id) {
                return dispatch({type: FORGOT_PASSWORD_SENDTOKEN_FINISHED, payload: {}});
            } else {
                return dispatch({type: FORGOT_PASSWORD_SENDTOKEN_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: FORGOT_PASSWORD_SENDTOKEN_FAILED, payload: err});
        });
    }
}


export const logoutUserA = () => ({type: LOGOUT_USER})

export const registerUserA = (userObject) => {
    return (dispatch) => {
        registerUserAPI(userObject).then((res) => {
            if (res.id) {
                dispatch(loginUserA(userObject.username, userObject.password));
                return dispatch({type: REGISTER_USER_FINISHED, payload: {}});
            } else {
                return dispatch({type: REGISTER_USER_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: REGISTER_USER_FAILED, payload: err});
        });
    }
}

export const registerInactiveUserA = (user, userObject) => {
    return (dispatch) => {
        registerInactiveUserAPI(user, userObject).then((res) => {
            if (res.id) {
                return dispatch({type: REGISTER_INACTIVE_USER_FINISHED, payload: res});
            } else {
                return dispatch({type: REGISTER_INACTIVE_USER_FAILED, payload: res});
            }
        }).catch((err) => {
            return dispatch({type: REGISTER_INACTIVE_USER_FAILED, payload: err});
        });
    }
}

const defaultState = {
    loginUserFinished: false,
    editUserFinished: false,
    editUserPasswordFinished: false,
    editUserErrors: {},
    loginUserErrors: {},
    registerUserErrors: {},
    access_token: null,
    token_type: 'Bearer',
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    gender: null,
    picture: null,
    id: 0,
    bets: [],
    climbs: [],
    admin: false,
    forgotPasswordErrors: {},
    newUser: {},
    newUserErrors: {},
  }


const reducer = (state=defaultState, action) => {
    switch (action.type) {
        case LOGOUT_USER:
            return {...state, ...defaultState};

        case LOGIN_USER_FINISHED:
            return {...state, loginUserFinished: true, ...action.payload, loginUserErrors: {}, newUser: {}};
        case LOGIN_USER_FAILED:
            return {...state, loginUserFinished: false, loginUserErrors: action.payload, newUser: {}};

        case EDIT_USER_FINISHED:
            return {...state, ...action.payload, editUserErrors: {}, editUserFinished: true, newUser: {}};
        case EDIT_USER_FAILED:
            return {...state, editUserErrors: action.payload, editUserFinished: false, newUser: {}};

        case EDIT_USER_PASSWORD_FINISHED:
            return {...state, ...action.payload, editUserErrors: {}, editUserPasswordFinished: true, newUser: {}};
        case EDIT_USER_PASSWORD_FAILED:
            return {...state, editUserErrors: action.payload, editUserPasswordFinished: false, newUser: {}};

        case REGISTER_USER_FINISHED:
            return  {...state, loginUserFinished: false, registerUserErrors: action.payload, newUser: {}};
        case REGISTER_USER_FAILED:
            return  {...state, loginUserFinished: false, registerUserErrors: action.payload, newUser: {}};

        case REGISTER_INACTIVE_USER_FINISHED:
            return  {...state, newUser: action.payload, newUserErrors: {}};
        case REGISTER_INACTIVE_USER_FAILED:
            return  {...state, newUser: {}, newUserErrors: action.payload};

        case FORGOT_PASSWORD_CHANGE_FINISHED:
            window.location.replace("/");
            return  {...state, forgotPasswordErrors: action.payload, newUser: {}};
        case FORGOT_PASSWORD_CHANGE_FAILED:
            return  {...state, loginUserFinished: false, newUser: {}, forgotPasswordErrors: action.payload};
        case FORGOT_PASSWORD_SENDTOKEN_FINISHED:
            return  {...state, loginUserFinished: false, newUser: {}, forgotPasswordErrors: action.payload};
        case FORGOT_PASSWORD_SENDTOKEN_FAILED:
            return  {...state, loginUserFinished: false, newUser: {}, forgotPasswordErrors: action.payload};
        default:
            return state;
    }
}   

export default reducer;