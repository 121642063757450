import { Typography, Grid, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forgotPasswordSendTokenA } from '../../redux/user'

const ForgotPasswordCard = ({sendToken, forgotPasswordErrors}) => {
  const [email, setEmail] = useState('')

  const submitHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    sendToken(email)
  }

  return (
    <form name="login" action="" onSubmit={(e) => submitHandle(e)} style={{height: '100%'}}>
    <Grid container justifyContent='center' spacing={2} flexDirection='column' alignItems='center' textAlign='center'>
      <Grid item>
        <Typography>
          Oubli de mot de passe
        </Typography>
      </Grid>
      <Grid item>
          <TextField
            required
            variant="outlined"
            label="Adresse email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
            <Button
              disabled={forgotPasswordErrors?.status}
              color='secondary'
              variant='contained'
              type="submit"
              sx={{ textTransform: 'none', maxWidth: '200px'  }}
            >
                Envoyer le code de récupération
            </Button>
          </Grid>
          <Grid item>
            <Typography color='red'>
              {forgotPasswordErrors?.detail}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color='green'>
              {forgotPasswordErrors?.status}
              N'oubliez pas de vérifier les spams
            </Typography>
          </Grid>
    </Grid>
    </form>
  )
}

const mapStateToProps = (state) => ({
  forgotPasswordErrors: state.user.forgotPasswordErrors
})

const mapDispatchToProps = (dispatch) => ({
  sendToken: (email) => dispatch(forgotPasswordSendTokenA(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordCard)