import { Box, Button, Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { registerInactiveUserA } from '../../redux/user'
import CloseIcon from '@mui/icons-material/Close';
import { mainGenders as genders } from '../../assets/category'
import { useEffect } from 'react';
import { getClimbersA } from '../../redux/climbers';

const CreateClimber = ({open, handleModal, registerUser, newUser, user, getClimbers, newUserErrors}) => {
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [gender, setGender] = useState(genders[0])
  useEffect(() => {
    if (newUser && newUser.id) {
      getClimbers(user)
      handleModal(false)
    }
  }, [newUser, user, getClimbers, handleModal])
  const registerUserHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    registerUser(user, {
      firstname,
      lastname,
      gender,
    })
  }
  return (
    <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
      <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', paddingBottom: 4, paddingLeft: 4, paddingRight: 4}}>
      <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
          <CloseIcon />
      </IconButton>
        <DialogTitle >
            Interface Admin pour rajouter un grimpeur
        </DialogTitle>
      <form name="register" action="" onSubmit={(e) => registerUserHandle(e)} sx={{height: '100%'}}>
        <Grid sx={{ paddingTop: '10px'}} container spacing={2} direction='column' justify='center' align='center'>
          <Grid item container direction='row' spacing={1} justifyContent='center'>
            <Grid item>
              <TextField
                required
                variant="outlined"
                label="Prénom"
                type="firstname"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                variant="outlined"
                label="Nom"
                type="lastname"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              select
              helperText="Choisissez une catégorie principale"
              variant="outlined"
              label="Catégorie"
              type="text"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              SelectProps={{
                native: true,
              }}
              >
              {genders.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item textAlign='center'>
          <Button
            color='secondary'
            variant='contained'
            type="submit"
            sx={{ textTransform: 'none' }}
          >
            <Typography>
              Créer un compte
            </Typography>
          </Button>
          </Grid>
          <Grid item>
              <Typography color='red'>
                {newUserErrors?.detail}
              </Typography>
            </Grid>
        </Grid>
      </form>
    </Box>
  </Dialog>
  )
}

const mapStateToProps = (state) => ({
  newUser: state.user.newUser,
  user: state.user,
  newUserErrors: state.user.newUserErrors
})

const mapDispatchToProps = (dispatch) => ({
  registerUser: (user, newUser) => dispatch(registerInactiveUserA(user, newUser)),
  getClimbers: (user) => dispatch(getClimbersA(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateClimber)