import { Grid, Table, TableContainer, TableHead, TableRow, Typography, Paper, TableCell, TextField, TableBody } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import SeasonPicker from '../../components/SeasonPicker';
import { getCurrentSeason } from '../../utils/getCurrentSeason';
import { genders } from '../../assets/category';
import { getRankingA } from '../../redux/ranking';
import { useEffect } from 'react';

export const Resultats = ({ranking, getRanking, user}) => {
  const [season, setSeason] = useState(getCurrentSeason())
  const [category, setCategory] = useState(genders[0])

  useEffect(() => {
    getRanking(season)
  }, [season])
  return (
    <Grid container direction='column' spacing={1} padding={1} justify='center'>
      <Grid item>
        <Typography>
          Classement général {getCurrentSeason() === season ? "provisoire" : "définitif"} pour la saison séléctionnée :
        </Typography>
      </Grid>
      <Grid item>
        <SeasonPicker season={season} setSeason={setSeason} />
      </Grid>
      {season === 2022 && !user.admin ? (
        <Typography>
          Le classement général pour cette saison n'est pas disponible sur l'application, voir sur le site officiel.
        </Typography>
      ) : (
        <>
          <Grid item>
            <TextField
                select
                helperText="Choisissez une catégorie"
                variant="outlined"
                label="Catégorie"
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                SelectProps={{
                    native: true,
                }}
            >
                {genders.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
                ))}
            </TextField>
          </Grid>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{padding: 0, background: 'white', zIndex: 3}} align="center">Place</TableCell>
                        <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1, background: 'white', zIndex: 3}} align="center">Grimpeur</TableCell>
                        <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1, background: 'white', zIndex: 3}} align="center">Total</TableCell>
                        {ranking?.events ? ranking.events.map((event) => {
                          return (
                            <TableCell sx={{padding: 0, paddingLeft: 1, paddingRight: 1, background: 'white', zIndex: 3}} align="center">{event.title}</TableCell>
                          )
                        }) : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                  {ranking?.res && ranking.res[category]  ? ranking.res[category].map((row) => (
                      <TableRow
                          key={row.climber}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'whitesmoke' }}
                      >
                          <TableCell component="th" scope="row" align="center" sx={{fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}}>
                              {row.place === 1 ? '🥇' : (
                                  row.place === 2 ? '🥈' : (
                                      row.place === 3 ? '🥉' : row.place
                              ))}
                          </TableCell>
                          <TableCell align="center" sx={{fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}}>{row.climber.firstname} {row.climber.lastname}</TableCell>
                          <TableCell align='center' sx={{fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}}>{row.points}</TableCell>
                          {ranking?.events ? ranking.events.map((event, index) => {
                                  if (row.events[event.id]) {
                                      return (
                                          <TableCell sx={{padding: 0, paddingLeft: 1, fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}} key={`${row.climber} ${index}`} align="center">{row.events[event.id].points}</TableCell>
                                      )
                                  } else {
                                      return (
                                          <TableCell key={`${row.climber} ${index}`} align="right" sx={{padding: 0, paddingLeft: 1, fontWeight: user && user.loginUserFinished && user.id === row.climber.id ? "bold" : "normal"}}></TableCell>
                                      )
                                  }
                          }) : null}
                      </TableRow>
                  )) : null}
                </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  ranking: state.rankings,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  getRanking: (season) => dispatch(getRankingA(season))
})

export default connect(mapStateToProps, mapDispatchToProps)(Resultats)