import { SET_AUTHTABS } from "./actionStrings";


export const toggleAuthTabsA = (authTabsNewState, tab, redirect) => ({
  type: SET_AUTHTABS,
  newState: authTabsNewState,
  tab,
  redirect,
});

const defaultAuthTabsState = {
  open: false,
  tab: null,
  redirect: null,
};

export const authTabsReducer = (state = defaultAuthTabsState, action) => {
  switch (action.type) {
    case SET_AUTHTABS: {
      return {
        open: action.newState,
        tab: action.tab,
        redirect: action.redirect,
      };
    }
    default: {
      return state;
    }
  }
};
