import { Checkbox, FormGroup, Dialog, Grid, FormControlLabel, Button, DialogTitle, IconButton, Typography, TextField, Switch } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import { editClimbsA, getClimbsA } from '../../redux/climbs';
import { toggleAuthTabsA } from '../../redux/authTabs';
import { genders, mainGenders } from '../../assets/category'
import { editUserA } from '../../redux/user'
import { Link } from 'react-router-dom';


export const BoulderCard = ({open, handleModal, event, getClimbs, editClimbs, user, climbs, openAuthTab, editUser, editClimbsErrors}) => {
    const [boulders, setBoulders] = useState([...Array(event.nb_boulders).keys()].map((x) => {return false}))
    const [category, setCategory] = useState(user.gender)
    const [abonnes, setAbonnes] = useState(false)
    const [categories, setCategories] = useState(genders)
    useEffect(() => {
        if (user.username && open && event.id)
            getClimbs(user, event.id)
    }, [getClimbs, user, open, event])

    useEffect(() => {
        // Get the event possible categories
        if (event && event.categories) {
            const newGenders = []
            genders.forEach((elt, index) => {
                if (JSON.parse(event.categories)[index])
                    newGenders.push(elt)
             })
             setCategories(newGenders)
        }
    }, [event.categories, event])

    useEffect(() => {
        // Get the user saved boulders
        if (climbs?.data && climbs.data.length > 0) {
            setBoulders(climbs.data)
        } else
            setBoulders([...Array(event.nb_boulders).keys()].map((x) => {return false}))
    }, [climbs, event])

    const handleChange = (index) => {
        // Handle change on boulders tick list
        return (e) => {
            const newBoulders = [...boulders];
            newBoulders[index] = e.target.checked;
            // console.log(newBoulders);
            setBoulders(newBoulders);
        }
    }

    const handleCategoryChange = (e) => {
        if (climbs && climbs.data && climbs.data.length > 0) {
            editClimbs(user, {
                data: JSON.stringify(climbs.data),
                event_id: event.id,
                category: e.target.value,
                abonnes: climbs.abonnes,
                climber_id: user.id
            })
        } else {
            setCategory(e.target.value)
            console.log("categorychangedbutnoclimbcreatedyet", e.target.value)
        }
    }

    const handleAbonnesChange = (e) => {
        if (climbs && climbs.data && climbs.data.length > 0) {
            editClimbs(user, {
                data: JSON.stringify(climbs.data),
                event_id: event.id,
                category: climbs.category,
                abonnes: e.target.checked,
                climber_id: user.id
            })
        } else {
            console.log("abonneschangedbutnoclimbcreatedyet", e.target.checked)
        }
        setAbonnes(e.target.checked)
    }

    const handleGenderChange = (e) => {
        if (mainGenders.includes(e.target.value)) {
            editUser({
                username: user.username,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                gender: e.target.value
            }, user.access_token)
        }
    }

    useEffect(() => {
        // if category successfully changed propagate in component state
        if (Object.keys(editClimbsErrors).length === 0 && climbs && climbs.category && category !== climbs.category) {
            setCategory(climbs.category)
            console.log("categorychanged")
        }
    }, [climbs, category, editClimbsErrors])

    useEffect(() => {
        // if abonnes successfully changed propagate in component state
        if (Object.keys(editClimbsErrors).length === 0 && climbs && climbs.abonnes && abonnes !== climbs.abonnes) {
            setAbonnes(climbs.abonnes)
            console.log("abonneschanged")
        }
    }, [climbs, abonnes, editClimbsErrors])

    const submitFormHandle = (e) => {
        if (e) {
            e.preventDefault()
        }
        editClimbs(user, {
            data: JSON.stringify(boulders),
            event_id: event.id,
            category: category,
            abonnes: abonnes,
            climber_id: user.id
        })
        handleModal(false)
    }

    if (! mainGenders.includes(user.gender)) {
        return (
            <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
                <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', paddingBottom: 4, paddingLeft: 4, paddingRight: 4}}>
                    <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle >
                        Veuillez choisir une des deux catégorie principale :
                    </DialogTitle>
                    <TextField
                        select
                        helperText="Choisissez une catégorie principale"
                        variant="outlined"
                        label="Catégorie"
                        type="text"
                        value={user.gender}
                        onChange={handleGenderChange}
                        SelectProps={{native: true,}}
                        >
                            <option key="empty" value=""></option>
                        {mainGenders.map((option) => {
                            return (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            )
                        })}
                    </TextField>
                </Box>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} onClose={() => handleModal(false)} aria-labelledby="form-dialog-title">
            <Box sx={{position: 'relative', flexDirection: 'column', alignItems: 'center', display: 'flex', paddingBottom: 4, paddingLeft: 4, paddingRight: 4}}>
                <DialogTitle >
                    Entrer ses blocs :
                </DialogTitle>
                <TextField
                    select
                    helperText="Choisissez une catégorie"
                    variant="outlined"
                    label="Catégorie"
                    type="text"
                    value={category}
                    onChange={handleCategoryChange}
                    SelectProps={{native: true,}}
                    >
                    {genders.map((option) => {
                        if (mainGenders.some((elt) => (option.toLocaleLowerCase().includes(elt.toLocaleLowerCase()) && user.gender !== elt))) {
                            return null
                        }
                        return (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        )
                    })}
                </TextField>
                {category !== user.gender ?
                    (
                        <>
                            <Typography>
                                Votre score sera automatiquement comptabilisé chez les {user.gender}s.
                            </Typography>
                            <Typography variant='caption'>
                                (aller dans <Link to="/profile">Paramètres</Link> pour changer son genre)
                            </Typography>
                        </>
                    ) : null
                }
                {event.abonnes ? (
                    <FormGroup>
                      <FormControlLabel control={<Switch checked={abonnes} />} label={`Abonné(e) ${event.place}`} labelPlacement="start" onChange={handleAbonnesChange}/>
                    </FormGroup>
                ) : null}
                <IconButton onClick={() => handleModal(false)} sx={{position: 'absolute', top: 0, right: 0}}>
                    <CloseIcon />
                </IconButton>
                {categories.includes(category) ? (
                    <form name="boulder" action="" onSubmit={(e) => submitFormHandle(e)} style={{flexDirection: 'column', alignItems: 'center', display: 'flex'}}>
                        <FormGroup>
                            <Grid container spacing={1} justify='center' align='center'>
                                {boulders.map((element, index) => {
                                    return (
                                        <Grid key={index} item xs={6}>
                                            <FormControlLabel
                                                key={`bloc_${index}`}
                                                control={<Checkbox checked={element} onChange={handleChange(index)}/>}
                                                label={`Bloc ${1 + index}`}
                                                labelPlacement="start"
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </FormGroup>
                        <Typography>
                            Tant que les qualifications sont ouvertes, vous pouvez revenir ajouter des blocs sur cette page (n'oubliez pas de sauvegarder).
                        </Typography>
                        <Button type='sumit' variant='contained' color='secondary'>
                            Sauvegarder
                        </Button>
                    </form>
                ) : (
                    <Typography>
                        Choisissez une catégorie (votre score sera automatiquement comptabilisé chez les {user.gender} (<Link to="/profile">Paramètres</Link> pour changer la categorie principale) : certaines étapes n'ouvrent pas les catégéories jeunes)
                    </Typography>
                )}
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    climbs: state.climbs,
    user: state.user,
    editClimbsErrors: state.climbs.editClimbsErrors,
})

const mapDispatchToProps = (dispatch) => ({
    getClimbs: (user, event_id) => dispatch(getClimbsA(user, event_id)),
    editClimbs: (user, climbs) => dispatch(editClimbsA(user, climbs)),
    openAuthTab: (newState, tab = null) => dispatch(toggleAuthTabsA(newState, tab)),
    editUser: (new_user, token) => dispatch(editUserA(new_user, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoulderCard)
