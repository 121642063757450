import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { editUserA, editUserPasswordA } from '../../redux/user'
import { mainGenders as genders } from '../../assets/category'



export const Profile = ({user, editUserErrors, editUser, editUserPassword}) => {

    const [username, setUsername] = useState(user.username)
    const [email, setEmail] = useState(user.email)
    const [firstname, setFirstName] = useState(user.firstname)
    const [lastname, setLastName] = useState(user.lastname)
    const [password, setPassword] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [gender, setGender] = useState(user.gender)

    const submitFormHandle = (e) => {
      if (e) {
        e.preventDefault();
      }

      if (
          username !== user.username ||
          email !== user.email ||
          firstname !== user.firstname ||
          lastname !== user.lastname ||
          gender !== user.gender
        ) {
            editUser({
                username,
                email,
                gender,
                firstname,
                lastname,
            }, user.access_token)
      }

      if (currentPassword && password && password === passwordRepeat && currentPassword !== password) {
        editUserPassword(currentPassword, password, user.access_token)
      }
    }

    if (! user.id) {
        window.location.replace("/");
        return null
    }

    return (
        <form name="register" action="" onSubmit={(e) => submitFormHandle(e)} style={{height: '100%', padding: 10}}>
        <Grid sx={{ paddingTop: '10px'}} container spacing={3} direction='column' justify='center' align='center'>
            <Grid item container direction='row' spacing={1} justifyContent='center'>
            <Grid item>
                <TextField
                required
                variant="outlined"
                label="Prénom"
                type="firstname"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                required
                variant="outlined"
                label="Nom"
                type="lastname"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                />
            </Grid>
            </Grid>
            <Grid item>
            <TextField
                required
                variant="outlined"
                label="Nom d'utilisateur"
                type="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            </Grid>
            <Grid item>
            <TextField
                required
                variant="outlined"
                label="Adresse email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            </Grid>
            <Grid item>
            <TextField
                select
                helperText="Choisissez votre catégorie principale"
                variant="outlined"
                label="Catégorie"
                type="text"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                SelectProps={{
                    native: true,
                }}
                >
                {genders.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
                ))}
            </TextField>
            </Grid>
            <Grid item>

                {username !== user.username ||
                email !== user.email ||
                firstname !== user.firstname ||
                lastname !== user.lastname ||
                gender !== user.gender ? (
                    <Typography color='orange'>
                        Les changements n'ont pas encore été sauvegardés
                    </Typography>
                ) : (
                    <Typography color='green'>
                        Profil à jour.
                    </Typography>
                )}
                </Grid>
            <Grid item>
                <Typography>
                    Changer de mot de passe (laissez vide si non).
                </Typography>
            </Grid>
            <Grid item>
            <TextField
                variant="outlined"
                label='Mot de passe actuel'
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
            />
            </Grid>
            <Grid item>
            <TextField
                variant="outlined"
                label='Nouveau Mot de passe'
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            </Grid>
            <Grid item>
            <TextField
                variant="outlined"
                error={password !== passwordRepeat}
                helperText={password !== passwordRepeat ? "Mots de passes différents" : ""}
                label='Confirmer le mot de passe'
                type="password"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
            />
            </Grid>
            <Grid item textAlign='center'>
            <Button
            color='secondary'
            variant='contained'
            type="submit"
            sx={{ textTransform: 'none' }}
            >
            <Typography>
                Enregistrer
            </Typography>
            </Button>
            </Grid>
            <Grid item>
                {user.editUserPasswordFinished ? (
                    <Typography color='green'>
                        Nouveau mot de passe enregistré.
                    </Typography>
                ) : null}
            </Grid>
            <Grid item>
                <Typography color='red'>
                {user.editUserErrors?.detail}
                </Typography>
            </Grid>
        </Grid>
        </form>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    editUser: (new_user, token) => dispatch(editUserA(new_user, token)),
    editUserPassword: (currentPassword, password, token) => dispatch(editUserPasswordA(currentPassword, password, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)